import React, { useState } from 'react';
import {
  Create,
  required,
  BooleanInput,
  FormDataConsumer,
  TextInput,
  TabbedForm,
  FormTab,
  NumberInput,
  useNotify,
  useRedirect,
  useDataProvider,
  minValue,
} from 'react-admin';
import CampaignSelect from './inputs/Campaign';
import ContentTypeSelect from './inputs/ContentType';
import PlacementSelect from './inputs/Placement';
import LimitsSelect from './inputs/Limits';
import TargetingSelect from './inputs/Targeting';
import Typography from '@material-ui/core/Typography';
import FormActions from '../common/FormActions';
import { redirect as redirectHelper } from '../../services/utils';
import { maxTextLength } from '../../services/validtors';
import { stripInheritedTargetingFields } from './inputs/Targeting';
import Sizes from './inputs/Sizes';
import { BANNER_TYPES } from './config';
import { createFile } from '../../services/FileService';

const WrapTabbedForm = ({ save, customSave, ...rest }) => {
  return <TabbedForm save={customSave} {...rest} />;
};

const BannerCreate = props => {
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const save = async srcData => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const { adFiles, ...prepData } = stripInheritedTargetingFields(srcData);
      const newAdFiles = {};

      for (const fileKey of Object.keys(adFiles)) {
        const fileInfo = adFiles[fileKey];

        if (fileInfo && fileInfo.rawFile) {
          const newFile = await createFile(fileInfo, dataProvider);
          newAdFiles[fileKey] = newFile;
        } else {
          newAdFiles[fileKey] = fileInfo;
        }
      }

      const updatedEntity = await dataProvider.create('banner', {
        data: {
          ...prepData,
          adFiles: newAdFiles,
        },
      });
      redirect(redirectHelper.toShow('/banner', updatedEntity.data.id));
    } catch (e) {
      notify(e.message, 'warning');
      console.error(e);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Create
        {...props}
        actions={<FormActions />}
        transform={data => stripInheritedTargetingFields(data)}
      >
        <WrapTabbedForm customSave={save}>
          <FormTab label="Banner">
            {/** Фрагмент нужен чтобы форма не устанавливала пропсы простым тегам */}
            <>
              <Typography variant="h5">Status</Typography>
            </>
            <BooleanInput source="isActive" defaultValue={false} />

            <>
              <Typography variant="h5">Info</Typography>
            </>
            <CampaignSelect />

            <>
              <Typography variant="h5">Banner</Typography>
            </>
            <TextInput
              source="name"
              validate={[required(), maxTextLength()]}
            />
            <NumberInput
              label="CPM"
              source="cpm"
              min={0}
              step={0.1}
              initialValue={0}
              validate={[required(), minValue(0)]}
            />

            <FormDataConsumer>
              {formDataProps => (
                <>
                  <ContentTypeSelect {...formDataProps} />

                  <Sizes {...formDataProps} />

                  <BooleanInput
                    className={formDataProps.className}
                    source="adData.isIframeWrapped"
                    defaultValue={false}
                    label="Wrap in to iFrame"
                    disabled={
                      formDataProps.formData.contentType !==
                      BANNER_TYPES.GENERIC_HTML
                    }
                  />
                </>
              )}
            </FormDataConsumer>
          </FormTab>
          <FormTab label="Placement">
            <PlacementSelect />
          </FormTab>
          <FormTab label="Limits">
            <LimitsSelect />
          </FormTab>
          <FormTab label="Targeting">
            <TargetingSelect />
          </FormTab>
        </WrapTabbedForm>
      </Create>
    </>
  );
};

export default BannerCreate;
