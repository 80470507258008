import React from 'react';
import { Resource } from 'react-admin';
import BannerList from './List';
import BannerEdit from './Edit';
import BannerCreate from './Create';
import BannerShow from './Show';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';

/**
 * @param {*} permissionCheck
 * @returns {*}
 */
const BannerResource = permissionCheck => {
  if (!permissionCheck({ banner: ['show'] })) {
    return null;
  }
  return (
    <Resource
      name="banner"
      show={BannerShow}
      list={permissionCheck({ banner: ['list'] }) ? BannerList : null}
      create={permissionCheck({ banner: ['create'] }) ? BannerCreate : null}
      edit={permissionCheck({ banner: ['edit'] }) ? BannerEdit : null}
      icon={LocalMoviesIcon}
    />
  );
};

export default BannerResource;
