import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DeleteButton,
  EditButton,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import BannerListActions from './ListActions';
import BannerListFilter from './ListFilter';
import BannerActiveNameField from './fields/ActiveName';
import CommonTargetingSummaryField from '../common/fields/TargetingSummary';
import CustomPagination from '../common/inputs/CustomPagination';
import BannerTargetingSourceField from './fields/TargetingSource';
import { makeStyles } from '@material-ui/core/styles';
import LimitsSummary from './fields/LimitsSummary';

const useStyles = makeStyles(theme => ({
  actionsCell: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
}));

const RowActions = ({ wrapClassName = '', ...props }) => (
  <div className={wrapClassName}>
    <EditButton {...props} undoable={false} />
    <DeleteButton {...props} undoable={false} />
  </div>
);

const BannerList = props => {
  const classes = useStyles(props);
  return (
    <List
      {...props}
      actions={<BannerListActions />}
      filters={<BannerListFilter />}
      bulkActionButtons={false}
      pagination={<CustomPagination />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <BannerActiveNameField label="Name" />
        <TextField label="Type" source="contentType" />
        <ReferenceField
          label="Advertiser"
          source="advertiserId"
          reference="advertisers"
          sortBy="advertiserName"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Campaign"
          source="campaignId"
          reference="campaigns"
          sortBy="campaignName"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField
          label="Zones"
          reference="banner_zone"
          source="bannerZonesIds"
          sortable={false}
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <CommonTargetingSummaryField label="Targeting" />
        <BannerTargetingSourceField label="Targeting source" />
        <LimitsSummary label="Limits" />
        <TextField label="Priority" source="priority" />
        <RowActions wrapClassName={classes.actionsCell} />
      </Datagrid>
    </List>
  );
};

export default BannerList;
