import React from 'react';
import { Resource } from 'react-admin';
import CampaignsList from './List';
import CampaignEdit from './Edit';
import CampaignCreate from './Create';
import CampaignShow from './Show';
import TrafficIcon from '@material-ui/icons/Traffic';

/**
 * AdminCore ждет именно Resource в качестве потомка (или ф-ю его возвращающую)
 * поэтому это не компонент, а именно функция, возвращающая компонет
 * @param {*} permissionCheck
 * @returns {*}
 */
const CampaignsResource = permissionCheck => {
  if (!permissionCheck({ campaigns: ['show'] })) {
    return null;
  }
  return (
    <Resource
      name="campaigns"
      show={CampaignShow}
      list={permissionCheck({ campaigns: ['list'] }) ? CampaignsList : null}
      edit={permissionCheck({ campaigns: ['edit'] }) ? CampaignEdit : null}
      create={
        permissionCheck({ campaigns: ['create'] }) ? CampaignCreate : null
      }
      icon={TrafficIcon}
    />
  );
};

export default CampaignsResource;
