import React from 'react';
import { Resource } from 'react-admin';
import SiteList from './List';
import SiteCreate from './Create';
import SiteEdit from './Edit';
import WebIcon from '@material-ui/icons/Web';

/**
 * @param {*} permissionCheck
 * @returns {*}
 */
const SiteResource = permissionCheck => {
  if (!permissionCheck({ site: ['show'] })) {
    return null;
  }
  return (
    <Resource
      name="site"
      list={permissionCheck({ site: ['list'] }) ? SiteList : null}
      edit={permissionCheck({ site: ['edit'] }) ? SiteEdit : null}
      create={permissionCheck({ site: ['create'] }) ? SiteCreate : null}
      icon={WebIcon}
    />
  );
};

export default SiteResource;
