import React from 'react';
import { Resource } from 'react-admin';
import AdvertisersList from './List';
import AdvertiserEdit from './Edit';
import AdvertiserShow from './Show/Show.jsx';
import AdvertiserCreate from './Create';

/**
 * AdminCore ждет именно Resource в качестве потомка (или ф-ю его возвращающую)
 * поэтому это не компонент, а именно функция, возвращающая компонет
 * @param {*} permissionCheck
 * @returns {*}
 */
const AdvertisersResource = permissionCheck => {
  if (!permissionCheck({ advertisers: ['show'] })) {
    return null;
  }
  return (
    <Resource
      name="advertisers"
      show={AdvertiserShow}
      list={permissionCheck({ advertisers: ['list'] }) ? AdvertisersList : null}
      edit={permissionCheck({ advertisers: ['edit'] }) ? AdvertiserEdit : null}
      create={
        permissionCheck({ advertisers: ['create'] }) ? AdvertiserCreate : null
      }
    />
  );
};

export default AdvertisersResource;
