import React from 'react';
import { FunctionField } from 'react-admin';

const TargetingSourceField = props => (
  <FunctionField
    {...props}
    render={({ isCustomRules }) => (isCustomRules ? 'Banner' : 'Campaign')}
  />
);

export default TargetingSourceField;
