import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import FormActions  from '../common/FormActions';
import { maxTextLength } from '../../services/validtors';

const SiteCreate = props => {
  return (
    <Create actions={<FormActions isShow={false} />} {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[required(), maxTextLength()]} />
      </SimpleForm>
    </Create>
  );
};

export default SiteCreate;
