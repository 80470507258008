import React from 'react';
import { TextField, ImageField, FileField } from 'react-admin';
import { BANNER_TYPES } from '../config';
import FileWithCopyField from '../../File/FileWithCopyField';

const getImageFields = record => {
  let key = 0;
  const getKey = () => (key += 1);
  const hasFile = !!record?.adFiles?.image;

  return [
    <TextField
      key={getKey()}
      label="Destination Url"
      source="adData.clickUrl"
    />,
    <TextField
      key={getKey()}
      label="Alt Text"
      source="adData.altText"
      emptyText="-"
    />,
    ...(!hasFile
      ? [
          <FileWithCopyField
            key={getKey()}
            label="Image Url"
            title="adData.srcUrl"
            source="adData.srcUrl"
            download
            emptyText="-"
          />,
          <ImageField key={getKey()} addLabel={false} source="adData.srcUrl" />,
        ]
      : [
          <FileWithCopyField
            key={getKey()}
            label="Image File"
            source="adFiles.image.src"
            title="adFiles.image.title"
            download
            emptyText="-"
          />,
          <ImageField
            key={getKey()}
            addLabel={false}
            source="adFiles.image.src"
          />,
        ]),
  ];
};

// TODO большое человеческое поле ввода кода
const getHTMLFields = () => {
  let key = 0;
  const getKey = () => (key += 1);

  return [
    <TextField
      key={getKey()}
      label="Code"
      source="adData.sourceCode"
      style={{ wordBreak: 'break-all' }}
    />,
  ];
};

const getLinkFields = () => {
  let key = 0;
  const getKey = () => (key += 1);

  return [
    <TextField
      key={getKey()}
      label="Destination Url"
      source="adData.clickUrl"
    />,
  ];
};

const BannerTypeVariation = record => {
  switch (record.contentType) {
    case BANNER_TYPES.IMAGE:
    case BANNER_TYPES.GIF:
    case BANNER_TYPES.VIDEO:
      return getImageFields(record);
    case BANNER_TYPES.GENERIC_HTML:
      return getHTMLFields();
    case BANNER_TYPES.LINK:
      return getLinkFields();
    default:
      return [];
  }
};

export default BannerTypeVariation;
