import React, { useCallback, useState } from 'react';
import { useChoices, useInput } from 'react-admin';
import getPath from 'lodash/get';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


export const CustomSelectInput = ({ children, ...props }) => {
  const {
    input: { onChange, value },
  } = useInput(props);

  return (
    <CustomSelect {...props} onChange={onChange} value={value}>{children}</CustomSelect>
  );
};

const CustomSelect = ({ children, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(false);


  const handleToggle = event => {
    setAnchorEl(prevEl => !prevEl ? event.currentTarget : null);
  };
  const onClose = () => {
    setAnchorEl(null);
  };


  const handleClick = event => {
    handleToggle(event);
    children.props.onClick && children.props.onClick(event);
  };

  return (
    <>
      { React.Children.only(
          React.cloneElement(children, { onClick: handleClick })
      )}
      <CsMenu {...props} {...{ anchorEl, onClose }} />
    </>
  )
};


const CsMenu = ({ anchorEl, onChange, onClose, choices, value, disableValue, ...props}) => {
  const { getChoiceText, getChoiceValue } = useChoices(props);
  const getSelectIndex = val => choices.findIndex(options => getChoiceValue(options) === val);
  const selectedIndex = getSelectIndex(value);

  const renderMenuItemOption = useCallback(options => getChoiceText(options), [
    getChoiceText,
  ]);

  const handleItemClick = (options, index) => {
    const isChanged = selectedIndex !== index;

    isChanged && onChange && onChange(getChoiceValue(options));

    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {choices.map((option, index) => (
        <MenuItem
          key={getChoiceValue(option)}
          value={getChoiceValue(option)}
          disabled={getPath(option, disableValue)}
          onClick={() => handleItemClick(option, index)}
          selected={index === selectedIndex}
        >
          {renderMenuItemOption(option)}
        </MenuItem>
      ))}
    </Menu>
)};

CsMenu.defaultProps = {
  emptyText: '',
  emptyValue: '',
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true,
  disableValue: 'disabled',
};

export default CustomSelect;
