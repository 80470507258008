import React from 'react';
import {
  NumberField,
  ChipField,
  ArrayField,
  SingleFieldList,
  Labeled,
} from 'react-admin';

import { Typography, Divider, Box } from '@material-ui/core';
import useStyles from '../../common/styles/show';

const TargetingField = ({record}) => {
  return !!record && <ChipField record={{name: record}} source="name" />
};

const TargetingShow = ({ headerSlot, ...props }) => {
  const classes = useStyles();
  const { record: { targetingRules } } = props;
  const { country } = targetingRules;
  const { isOut: isBlackCountryList } = country || {};
  return (
    <Box className={classes.infoBoxContainer}>
      <Box className={classes.infoBoxColumn}>

        { headerSlot }
      {/* <Typography >Limits</Typography>
        <Labeled label="Day Limit">
          <NumberField
            {...props}
            source="targetingRules.dayLimit"
            emptyText="Not set"
          />
        </Labeled>
        <Labeled label="Overall Limit">
          <NumberField
            {...props}
            source="targetingRules.overallLimit"
            emptyText="Not set"
          />
        </Labeled>
        <Divider /> */}
        <Typography className={classes.subTitle}>Device Type</Typography>
        <TargetingField record={targetingRules.device} />
        <Divider />

        <Typography className={classes.subTitle}>OS</Typography>
        <ArrayField className={classes.subBlock} {...props} label="" source="targetingRules.os.list">
          <SingleFieldList>
            <TargetingField />
          </SingleFieldList>
        </ArrayField>
        <Divider />
        <Typography className={classes.subTitle} >Browser</Typography>
        <ArrayField className={classes.subBlock} {...props} label="" source="targetingRules.browser.list">
          <SingleFieldList>
            <TargetingField />
          </SingleFieldList>
        </ArrayField>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box className={classes.infoBoxColumn}>
        <Typography className={classes.subTitleFirst} >Locale</Typography>
        <ArrayField className={classes.subBlock} {...props} label="" source="targetingRules.locale.list">
          <SingleFieldList>
            <TargetingField />
          </SingleFieldList>
        </ArrayField>
        <Divider />
        <Typography className={classes.subTitle} >GEO</Typography>
        {country && (
          <Labeled label={isBlackCountryList ? 'Black list' : 'White list'}>
              <ArrayField className={classes.subBlock} {...props} label="" source="targetingRules.country.list">
              <SingleFieldList>
                <TargetingField />
              </SingleFieldList>
            </ArrayField>
          </Labeled>
        )}
      </Box>
    </Box>
  );
};

export default TargetingShow;
