import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {
  ImageField,
  ImageInput,
  regex,
  required,
  SelectInput,
  TextInput,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { BANNER_TYPES, BANNER_TYPES_LABELS } from '../config';

const useStyles = makeStyles(theme => ({
  fieldsWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  select: {
    width: '256px',
  },
}));

const validateURL = regex(/^https?:\/\//i, 'Must be a valid URL');

const validateImageFile = (value, all) => {
  if (!value && (!all.adData || !all.adData.srcUrl)) {
    return 'Either Image Url or Image File should be specified';
  }
  return false;
};

const validateImageUrl = (value, all) => {
  if (!value && (!all.adFiles || !all.adFiles.image)) {
    return 'Either Image Url or Image File should be specified';
  }
  return false;
};

const BannerTypeImage = () => {
  const form = useForm();
  return (
    <>
      <TextInput
        label="Destination Url"
        source="adData.clickUrl"
        validate={[required(), validateURL]}
      />
      <TextInput label="Alt Text" source="adData.altText" />
      <TextInput
        label="Image Url"
        source="adData.srcUrl"
        validate={[validateURL, validateImageUrl]}
        onChange={({ target: { value } }) => {
          if (!value) {
            return;
          }
          form.change('adFiles.image', null);
        }}
      />
      <ImageInput
        source="adFiles.image"
        label="Image File"
        accept="image/*"
        validate={validateImageFile}
        onChange={val => {
          if (!val) {
            return;
          }
          form.change('adData.srcUrl', null);
        }}
      >
        <ImageField source="src" />
      </ImageInput>
    </>
  );
};

// TODO большое человеческое поле ввода кода
const BannerTypeGenericHtml = () => {
  return (
    <TextInput
      label="Code"
      source="adData.sourceCode"
      multiline
      type="text"
      validate={required()}
      rows={5}
    />
  );
};

const BannerTypeLink = () => {
  return (
    <TextInput
      label="Destination Url"
      source="adData.clickUrl"
      validate={[required(), validateURL]}
    />
  );
};

const BannerTypeVariation = props => {
  const {
    formData: { contentType },
  } = props;

  switch (contentType) {
    case BANNER_TYPES.IMAGE:
    case BANNER_TYPES.GIF:
      return <BannerTypeImage {...props} />;
    case BANNER_TYPES.GENERIC_HTML:
      return <BannerTypeGenericHtml {...props} />;
    case BANNER_TYPES.LINK:
      return <BannerTypeLink {...props} />;
    default:
      return null;
  }
};

const ContentTypeSelect = props => {
  const form = useForm();
  const classes = useStyles(props);
  const types = Object.entries(BANNER_TYPES_LABELS).map(([id, name]) => ({
    id,
    name,
  }));

  return (
    <div className={classes.fieldsWrap}>
      <SelectInput
        source="contentType"
        optionText="name"
        choices={types}
        onChange={({ target: { value } }) => {
          form.batch(() => {
            form.change('adData', {});
            form.change('adFiles', {});
          });
        }}
        className={classes.select}
        validate={required()}
      />
      <BannerTypeVariation {...props} />
    </div>
  );
};

export default ContentTypeSelect;
