const JWTManager = () => {
  const LOGOUT_EVENT_NAME = 'ra-logout';
  let refreshUrl = null;
  let jsonWebToken = null;
  let refreshTimeOutId;
  let refreshingProcess = null;

  // Если в какой-то табе разлогинились - выходим во всех (на будущее, можно реализовать через вебсокеты)
  window.addEventListener('storage', event => {
    if (event.key === LOGOUT_EVENT_NAME) {
      jsonWebToken = null;
    }
  });

  const setRefreshTokenUrl = url => refreshUrl = url;
  const refreshToken = delay => {
    refreshTimeOutId = window.setTimeout(
        getRefreshedToken,
        delay * 1000 - 5000
    ); // Validity period of the token in seconds, minus 5 seconds
  };
  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };
  const waitForTokenRefresh = () => {
    if (!refreshingProcess) {
      return Promise.resolve();
    }
    return refreshingProcess;
  };

  const getInitToken = () => {
    if (jsonWebToken) {
      return Promise.resolve(jsonWebToken);
    }

    if (!refreshingProcess) {
      getRefreshedToken();
    }
    return refreshingProcess
      .then(() => jsonWebToken)
      .catch(() => jsonWebToken);
  };

  const getRefreshedToken = () => {
    const request = new Request(refreshUrl, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'include',
    });
    refreshingProcess = fetch(request)
      .then(response => {
        if (response.status !== 200) {
          clearToken();
          return { token: null };
        }
        return response.json();
      })
      .then(({ token, expiresIn }) => {
        refreshingProcess = null;

        if (token) {
          setToken(token, expiresIn);
          return true;
        }

        return false;
      }).catch(e => {
        refreshingProcess = null;
        throw e;
      });

    return refreshingProcess;
  };

  const getToken = () => jsonWebToken;

  const setToken = (token, delay) => {
    jsonWebToken = token;
    refreshToken(delay);
    return true;
  };

  const clearToken = () => {
    jsonWebToken = null;
    abortRefreshToken();
    window.localStorage.setItem(LOGOUT_EVENT_NAME, Date.now());
    return true;
  };

  return {
    clearToken,
    getToken,
    setToken,
    setRefreshTokenUrl,
    getRefreshedToken,
    waitForTokenRefresh,
    getInitToken,
  }
};

export default JWTManager();
