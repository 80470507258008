
import React, { useState, useEffect } from 'react';
import {
  BooleanInput,
  useDataProvider,
  Loading,
  NumberInput,
} from 'react-admin';
import { useForm, useFormState} from 'react-final-form';

import TargetingSelect from '../../common/inputs/Targeting';

export const INHERITED_TARGETING_FIELDS = {
  dateStart: {
    defaultValue: null,
  },
  dateEnd: {
    defaultValue: null,
  },
  targetingRules: {
    defaultValue: {},
  },
};

export const stripInheritedTargetingFields = data => {
  const replace = !data.isCustomRules ? Object.entries(INHERITED_TARGETING_FIELDS).reduce((res, [ name, { defaultValue }]) => {
    return {
      ...res,
      [name]: defaultValue
    };
  }, {}) : {};
  return {
    ...data,
    ...replace
  }
}

export const setInheritedTargetingFields = (form, isCustom, parent) => {
  form.batch(() => {
    Object.entries(INHERITED_TARGETING_FIELDS).forEach(([ name, { defaultValue } ]) => {
      if (isCustom) {
        form.change(name, defaultValue);
      } else if (parent) {
        form.change(name, parent[name]);
      }
    });
  });
}

const Targeting = props => {
  const dataProvider = useDataProvider();
  const form = useForm();
  const { isCustomRules, campaignId } = useFormState().values;
  const [lastCampaign, setLastCampaign] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (!campaignId || (lastCampaign && lastCampaign.id === campaignId) || isLoading) {
      return;
    }
    setIsLoading(true);

    dataProvider.getOne('campaigns', { id: campaignId })
      .then(({ data: campaign }) => {
        setLastCampaign(campaign);
        setIsLoading(false);

        if (!isCustomRules) {
          setInheritedTargetingFields(form, isCustomRules, campaign);
        }
      }).catch(e => {
        console.error(e);
        setIsLoading(false);
      });
  }, [campaignId, dataProvider, form, isCustomRules, isLoading, lastCampaign])

  if (isLoading) {
    return <Loading />;
  }
  const onFlagChange = val => {
    setInheritedTargetingFields(form, val, lastCampaign);
  };

  const validateNumber = (value, formData) => {
    if (!/^-?\d+$/.test(value)) {
      return 'Must be an integer';
    }
  };

  return (
    <>
      <NumberInput
        label="Priority"
        source="priority"
        step={1}
        validate={validateNumber}
      />
      <BooleanInput
        label="Overwrite campaign rules"
        source="isCustomRules"
        onChange={onFlagChange}
        defaultValue={false}
      />
      <TargetingSelect disabled={!isCustomRules} {...props} />
    </>
  );
};

export default Targeting;
