import React  from 'react';
import { TextInput, ArrayInput, SimpleFormIterator, required, email } from 'react-admin';
import  { maxTextLength } from '../../services/validtors';

const ContactsSubForm = props => (
  <ArrayInput source="contacts" {...props}>
    <SimpleFormIterator>
      <TextInput source="name" label="Person" validate={[required(), maxTextLength()]}/>
      <TextInput source="jobName" label="Job title" validate={[maxTextLength()]}/>
      <TextInput source="email" label="Email" validate={[email(), maxTextLength(30)]} />
      <TextInput source="skype" label="Skype" validate={[maxTextLength(30)]} />
    </SimpleFormIterator>
  </ArrayInput>
);

export default ContactsSubForm;
