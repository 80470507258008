import React from 'react';
import { useQuery, useDataProvider } from 'react-admin';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteSweep';

const RemoveFromZoneButton = props => {
  const { record, zoneId, refresh, path} = props;
  const dataProvider = useDataProvider();
  const { data } = useQuery({
    type: 'getOne',
    resource: 'banner',
    payload: { id: record.id }
  });

  const handleRemove = e => {
    const diff = Object.assign(record, { bannerZonesIds: data?.bannerZonesIds?.filter(item => item !== zoneId)})
      dataProvider.update(
        'banner',
        { id: record.id, data: diff }
      ).then(
        setTimeout(() => refresh(path, path), 500)
      ).catch(
        () => {}
      )
    e.stopPropagation()
  };

  return (
    <div>
      <IconButton onClick={handleRemove} style={{color : 'red'}}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default RemoveFromZoneButton;
