import { Button, TableCell, TableSortLabel } from '@material-ui/core';
import { Link } from 'react-admin';
import React from 'react';
import { intListReducer, anyListReducer, dateReducer } from './reducers';

export const GROUP_FIELD_CONFIG = {
  eventDate: {
    title: 'EventDate',
    renderCell: ({ record, store, ...props }) => (
      <TableCell {...props}>{record.eventDate}</TableCell>
    ),
  },
  advertiserId: {
    title: 'Advertiser',
    renderCell: ({ record, store, ...props }) => (
      <TableCell {...props}>
        <Link to={{ pathname: `/advertisers/${record.advertiserId}/show` }}>
          {store && store.advertisers && store.advertisers[record.advertiserId]
            ? store.advertisers[record.advertiserId].name
            : `Advertiser #${record.advertiserId}`}
        </Link>

        {store.advertisers[record.advertiserId]?.dashboard && (
          <Button
            href={store.advertisers[record.advertiserId].dashboard}
            style={{ marginLeft: '20px' }}
            color="primary"
          >
            Dashboard
          </Button>
        )}
      </TableCell>
    ),
  },
  campaignId: {
    title: 'Campaign',
    renderCell: ({ record, store, ...props }) => (
      <TableCell {...props}>
        <Link to={{ pathname: `/campaigns/${record.campaignId}/show` }}>
          {store && store.campaigns && store.campaigns[record.campaignId]
            ? store.campaigns[record.campaignId].name
            : `Campaign #${record.campaignId}`}
        </Link>
      </TableCell>
    ),
  },
  bannerId: {
    title: 'Banner',
    fieldsCount: 2,
    renderCell: ({ record, store, key, ...props }) => {
      const lRecord =
        store && store.banner && store.banner[record.bannerId]
          ? store.banner[record.bannerId]
          : null;

      return [
        <TableCell key={`${key}-name`} {...props}>
          <Link to={{ pathname: `/banner/${record.bannerId}/show` }}>
            {lRecord ? lRecord.name : `Banner #${record.bannerId}`}
          </Link>
        </TableCell>,
        <TableCell key={`${key}-cpm`} {...props}>
          {lRecord && lRecord.cpm > 0 ? `${lRecord.cpm}$` : '-'}
        </TableCell>,
      ];
    },
    renderHead: ({ key, orderBy, orderDir, createSortHandler, ...props }) => {
      return [
        <TableCell
          key={`${key}-name`}
          sortDirection={orderBy === 'bannerId' ? orderDir : false}
        >
          <TableSortLabel
            active={orderBy === 'bannerId'}
            direction={orderBy === 'bannerId' ? orderDir : 'asc'}
            onClick={createSortHandler('bannerId')}
          >
            Banner
          </TableSortLabel>
        </TableCell>,
        <TableCell key={`${key}-cpm`} {...props}>
          CPM
        </TableCell>,
      ];
    },
  },
  siteId: {
    title: 'Site',
    renderCell: ({ record, store, ...props }) => (
      <TableCell {...props}>
        <Link to={{ pathname: `/site/${record.siteId}` }}>
          {store && store.site && store.site[record.siteId]
            ? store.site[record.siteId].name
            : `Site #${record.siteId}`}
        </Link>
      </TableCell>
    ),
  },
  zoneId: {
    title: 'Zone',
    renderCell: ({ record, store, ...props }) => (
      <TableCell {...props}>
        <Link to={{ pathname: `/banner_zone/${record.zoneId}/show` }}>
          {store && store.banner_zone && store.banner_zone[record.zoneId]
            ? store.banner_zone[record.zoneId].name
            : `Zone #${record.zoneId}`}
        </Link>
      </TableCell>
    ),
  },
  country: {
    title: 'Country',
    renderCell: ({ record, store, ...props }) => (
      <TableCell {...props}>{record.country}</TableCell>
    ),
  },
  deviceType: {
    title: 'Device Type',
    renderCell: ({ record, store, ...props }) => (
      <TableCell {...props}>{record.deviceType}</TableCell>
    ),
  },
  os: {
    title: 'OS',
    renderCell: ({ record, store, ...props }) => (
      <TableCell {...props}>{record.os}</TableCell>
    ),
  },
  browser: {
    title: 'Browser',
    renderCell: ({ record, store, ...props }) => (
      <TableCell {...props}>{record.browser}</TableCell>
    ),
  },
};

export const SEARCH_FIELD_CONFIG = {
  advertiserId: {
    reducer: intListReducer,
  },
  campaignId: {
    reducer: intListReducer,
  },
  bannerId: {
    reducer: intListReducer,
  },
  siteId: {
    reducer: intListReducer,
  },
  zoneId: {
    reducer: intListReducer,
  },
  country: {
    reducer: anyListReducer,
  },
  dateFrom: {
    reducer: dateReducer,
  },
  dateTo: {
    reducer: dateReducer,
  },
};

export const DEFAULT_GROUP_BY = ['advertiserId', 'campaignId', 'bannerId'];
