export const intListReducer = (container, field, value) => {
  if (!Array.isArray(value)) {
    return container;
  }
  return {
    ...container,
    [field]: value.map(el => Number.parseInt(el, 10)),
  };
};

export const anyListReducer = (container, field, value) => {
  if (!Array.isArray(value)) {
    return container;
  }
  return {
    ...container,
    [field]: value,
  };
};

export const dateReducer = (container, field, value) => {
  let date = new Date(value);

  if (Number.isNaN(date)) {
    return container;
  }
  // de-UTC
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return {
    ...container,
    [field]: date,
  };
};
