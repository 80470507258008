import React from 'react';
import { required, ReferenceInput, SelectInput } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  fieldsWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const CampaignSelectInput = props => {
  return (
    <SelectInput
      {...props}
      optionText="name"
      allowEmpty={!props.choices.length}
      validate={required()}
      emptyText="-- Empty list --"
    />
  )
};

const CampaignSelect = props => {
  const classes = useStyles(props);
  const form = useForm();
  const { values } = useFormState();
  const { advertiserId } = values;
  const campaignFilter = advertiserId ? { advertiserId } : {};
  const isCampDisabled = !advertiserId;

  return (
    <div className={`${classes.fieldsWrap} ${props.className || ''}`}>
      <ReferenceInput
        label="Advertiser"
        source="advertiserId"
        reference="advertisers"
        onChange={() => form.change('campaignId', null)}
        validate={required()}
        resettable
      >
        <SelectInput optionText="name" validate={required()} />
      </ReferenceInput>

      <ReferenceInput
        label="Campaign"
        source="campaignId"
        reference="campaigns"
        filter={campaignFilter}
        disabled={isCampDisabled}
        validate={required()}
        resettable
        allowEmpty={true}
      >
        <CampaignSelectInput />
      </ReferenceInput>
    </div>
  );
};

export default CampaignSelect;
