import React, {useEffect, useState} from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  required,
  BooleanInput,
  SelectInput,
  useDataProvider,
} from 'react-admin';
import FormActions from '../common/FormActions';
import TargetingSelect from '../common/inputs/Targeting';
import { maxTextLength } from '../../services/validtors';

const CampaignEdit = props => {
  const [advertisers, setAdvertisers,] = useState();
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('advertisers', {pagination: {}})
      .then(({ data }) => {
        setAdvertisers(data);
      }).catch(err => {});
  }, [dataProvider]);

  return (
    <Edit {...props} undoable={false} actions={<FormActions />}>
      <TabbedForm redirect="show">
        <FormTab label="Info">
          <TextInput source="name" validate={[required(), maxTextLength()]} />
          <BooleanInput source="isActive" />
          <SelectInput source="advertiserId" choices={advertisers} optionText="name" validate={required()} />
        </FormTab>
        <FormTab label="Targeting">
          <TargetingSelect />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CampaignEdit;
