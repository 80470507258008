export const BANNER_TYPES = {
  VIDEO: 'video',
  IMAGE: 'image',
  GIF: 'gif',
  GENERIC_HTML: 'generic_html',
  LINK: 'link',
};

export const BANNER_TYPES_LABELS = {
  [BANNER_TYPES.GENERIC_HTML]: 'Generic HTML/Script',
  [BANNER_TYPES.IMAGE]: 'Image/GIF',
  //[BANNER_TYPES.VIDEO]: 'Video',
  //[BANNER_TYPES.LINK]: 'Link',
};

export const EVENT_TYPES = {
  REQUEST: 1,
  IMPRESSION: 2,
  CLICK: 3,
};

export const EVENT_TYPE_TITLE = {
  [EVENT_TYPES.REQUEST]: 'Request',
  [EVENT_TYPES.IMPRESSION]: 'Impression',
  [EVENT_TYPES.CLICK]: 'Click',
}
