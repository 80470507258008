import React, { useMemo } from 'react';
import { FileField } from 'react-admin';
import { CopyToClipboardButton } from '../common/CopyToClipboard';

const FileWithCopyField = props => {
  const sourceVal = useMemo(() => {
    const sourcePath = props.source.split('.');
    let last = props.record;

    for (const el of sourcePath) {
      if (!(el in last)) {
        return null;
      }
      last = last[el];
    }
    return last;
  }, [props.source, props.record]);

  return (
    <>
      <FileField {...props} />
      {props.record && <CopyToClipboardButton text={sourceVal} />}
    </>
  );
};

export default FileWithCopyField;
