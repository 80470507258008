import React from 'react';
import { regex } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ResettableInput from '../../common/inputs/ResettableInput';
import StyleSizeInput, { DIGIT_TYPE } from '../../common/inputs/StyleSizeInput';
import {BANNER_TYPES} from '../config';

const useStyles = makeStyles(theme => ({
  cross: {
    margin: '-12px 1em 0 1em',
    display: 'inline-block',
  },
}));

const validatorSize = regex(
  new RegExp(`^(\\d+)(${Object.values(DIGIT_TYPE).join('|')})$`),
  'Size can\'t be less then 0'
);


const Sizes = ({ formData, ...props }) => {
  const classes = useStyles(props);
  const { contentType, adData } = formData;
  const { isIframeWrapped } = adData || {};
  const disabled = contentType === BANNER_TYPES.GENERIC_HTML && !isIframeWrapped;
  return (
    <ResettableInput source="adData.styles" disabled={disabled}>
      <>
        <StyleSizeInput
          label="Width"
          source="adData.styles.width"
          min={1}
          validate={validatorSize}
          disabled={disabled}
        />
        <span className={classes.cross}>&#10005;</span>
        <StyleSizeInput
          label="Height"
          source="adData.styles.height"
          min={1}
          validate={validatorSize}
          disabled={disabled}
        />
      </>
    </ResettableInput>
  )
};

export default Sizes;
