export const requestToModel = ({ id, attributes }) => ({
  id,
  ...attributes
});
export const modelToRequest = ({ id, ...attributes }) => ({
  id,
  attributes
});

export const responseListModelsPrepare = json => {
  const { data, meta = {} } = json;
  const total = meta['total'] || meta['total-count'] || data.length;

  return ({
    data: data.map(requestToModel),
    total,
  });
};

export const responseOneModelPrepare = ({ data }) => ({ data: requestToModel(data) });

export const iterableMany = (resource, params, oneModelHandler) => {
  const { ids, data } = params;

  return Promise.all(
    ids.map(id => oneModelHandler(resource, { id, data }))
  )
    .then(dataList => ({ data: dataList.map(({ id }) => id) }));
};

export const requestUpdateModelPrepare = ({ id, ...data }) => JSON.stringify({ data: modelToRequest(data) });
