// TODO: заменить на query-string;
import { stringify } from 'qs';
import { initListQuery, filterQuery } from './utils';
import { iterableMany, responseListModelsPrepare, responseOneModelPrepare, requestUpdateModelPrepare } from './preparator';
import httpRequester from './httpClient';


const builder = (apiUrl, options = {}) => {
  if (!apiUrl) {
    throw new Error('API url (first argument) must have!')
  }
  const { httpClient = httpRequester } = options;

  const methods = {
    getList: (resource, params) => {
      const query = {
        ...initListQuery(params),
        ...filterQuery(params),
      };

      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url)
        .then(responseListModelsPrepare);
    },

    getOne: (resource, params) => {
      const url = `${apiUrl}/${resource}/${params.id}`;

      return httpClient(url)
        .then(responseOneModelPrepare);
    },

    getMany: (resource, params) => {
      const query = {
        filter: {
          id: params.ids,
        }
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      return httpClient(url)
        .then(responseListModelsPrepare);
    },

    getManyReference: (resource, params) => {
      const query = {
        ...initListQuery(params),
        ...filterQuery(params),
      };

      // Add the reference id to the filter params.
      query[`filter[${params.target}]`] = params.id;

      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return httpClient(url)
        .then(responseListModelsPrepare);
    },

    create: (resource, params) => {
      const url = `${apiUrl}/${resource}`;
      return httpClient(url, {
        method: 'POST',
        body: requestUpdateModelPrepare(params.data)
      })
        .then(responseOneModelPrepare);
    },

    update: (resource, params) => {
      const { id } = params;
      const url = `${apiUrl}/${resource}/${id}`;

      return httpClient(url, {
        method: 'PATCH',
        body: requestUpdateModelPrepare(params.data)
      })
        .then(responseOneModelPrepare);
    },

    delete: (resource, params) => {
      const { id } = params;
      const url = `${apiUrl}/${resource}/${id}`;
      return httpClient(url, {
        method: 'DELETE',
      })
    },

    request: (resource, config) => {
      const url = `${apiUrl}/${resource.replace(/^\//,'')}`;
      return httpClient(url, config);
    },
  };

  Object.assign(methods, {
    updateMany: (resource, params) => iterableMany(resource, params, methods.update),

    deleteMany: (resource, params) => {
      return iterableMany(resource, params, methods.delete)
    },
    }
  );

  return methods;
};

export default builder
