import React, { useState, useEffect } from 'react';
import {
  useDataProvider,
  Loading,
  Error,
  CheckboxGroupInput,
} from 'react-admin';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    root: {
      backgroundColor: theme.palette.grey[300],
    },
  };
});

const initSiteZonesLists = provider =>
  provider.getList('banner_zone', { pagination: {} }).then(({ data }) => {
    const list = {};
    data.forEach(bannerZones => {
      if (!list[bannerZones.siteId]) {
        list[bannerZones.siteId] = [];
      }
      list[bannerZones.siteId].push(bannerZones);
    });
    return list;
  });

const initSiteList = provider =>
  provider.getList('site', { pagination: {} }).then(({ data }) => data);

const PlacementSelect = ({ formData, ...allProps }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [siteList, setSiteList] = useState([]);
  const [siteZonesLists, setSiteZonesLists] = useState({});
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (isInit) {
      return;
    }
    setIsInit(true);
    Promise.all([initSiteList(dataProvider), initSiteZonesLists(dataProvider)])
      .then(([sList, szList]) => {
        setSiteList(sList);
        setSiteZonesLists(szList);
        setIsLoading(false);
      })
      .catch(e => {
        setError(e);
        setIsLoading(false);
      });
  }, [isInit, dataProvider]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <>
      {siteList.map(site => {
        const choices = siteZonesLists[site.id] || [];
        const isDisabled = choices.length === 0;
        return (
          <Accordion key={site.id} defaultExpanded={!isDisabled} disabled={isDisabled}>
            <AccordionSummary className={classes.root}>
              <Typography>{`${site.id}: ${site.name}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CheckboxGroupInput
                label=""
                source="bannerZonesIds"
                choices={choices}
                row={false}
                optionText={record => `${record.id}: ${record.name}`}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default PlacementSelect;
