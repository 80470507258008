import React  from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

const AddNotesBtn = ({ parentId, parentType, label = 'Add note' }) => (
  <Button
    component={Link}
    to={{
      pathname: '/notes/create',
      search: `?filter%5BparentType%5D=${parentType}&filter%5BparentId%5D=${parentId}`
    }}
    label={label}
  >
    <ChatBubbleIcon />
  </Button>
);

export default AddNotesBtn;
