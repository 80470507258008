import React from 'react';
import { DateField, TextField, Datagrid, EditButton, DeleteButton, ListBase, Pagination } from 'react-admin';
import {rowsPerPageOptions} from '../../config';

const Actions = props => {
  return (
    <div style={{ float: 'right', marginLeft: '5px' }}>
      <EditButton style={{ minWidth: 0, marginRight: '5px' }} title="Edit" label="" {...props} />
      <DeleteButton style={{ minWidth: 0 }} title="Delete" label="" {...props} resource="notes" redirect={false} />
    </div>
  )
}

const NotesList = ({ parentId, parentType }) => {
  return (
    <ListBase
      title=""
      resource="notes"
      basePath="/notes"
      actions={true}
      hasShow={false}
      hasList={true}
      hasEdit={true}
      hasCreate={true}
      filter={{ parentId, parentType }}
    >
      <Datagrid rowClick="edit">
        <DateField showTime source="createdAt" style={{ fontSize: 'inherit' }} />
        <TextField source="text" />
        <Actions />
      </Datagrid>
      <Pagination rowsPerPageOptions={rowsPerPageOptions} />
    </ListBase>
  )
};

export default NotesList;
