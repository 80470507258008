import React, { cloneElement } from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  FileField,
  List,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import CustomPagination from '../common/inputs/CustomPagination';
import FileWithCopyField from './FileWithCopyField';

const ListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const RowActions = ({ ...props }) => (
  <div style={{ textAlign: 'right' }}>
    <DeleteButton {...props} undoable={false} />
  </div>
);

const FileList = props => {
  return (
    <List
      {...props}
      pagination={<CustomPagination />}
      actions={<ListActions />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="type" />
        <TextField source="title" fullWidth />
        <FileWithCopyField
          source="src"
          title="path"
          label="Download"
          download
        />
        <DateField source="createdAt" showTime label="Created" />
        <RowActions />
      </Datagrid>
    </List>
  );
};

export default FileList;
