import React, { useEffect, useState, useMemo } from 'react';
import {
  useDataProvider,
  useAuthState,
  useLogout,
  usePermissions,
  Loading,
  Title,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CardActions,
  Button,
  TableSortLabel,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getPermissionsChecker } from '../../services/Permissions';
import { GROUP_FIELD_CONFIG } from './FieldConfig';
import { calcCtr, getInitialSearchState, createActions } from './helpers';

import FormFilter from './FormFilter';
import FormGroupBy from './FormGroupBy';
import { stringify } from 'qs';
import { format } from 'date-fns';
import { API_URL } from '../../config';

const useStyles = makeStyles(theme => ({
  controlsCard: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'inline-block',
    width: '460px',
  },
  controlsCardActions: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  },
}));

const GeneralStatistics = props => {
  const [
    needInitFetch,
    initFilter,
    initGroup,
    initOrder,
    initPage,
  ] = useMemo(() => getInitialSearchState(props.location), [props.location]);
  const { loading: isLoadingPermissions, permissions } = usePermissions();
  const authState = useAuthState();
  const logout = useLogout();
  const dataProvider = useDataProvider();
  const [errors, setErrors] = useState({});
  const [resourceStore, setResourceStore] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const [filter, setFilter] = useState(initFilter);
  const [groupByForm, setGroupByForm] = useState([...initGroup]);

  const [groupByTable, setGroupByTable] = useState(initGroup);
  const [orderByOpts, setOrderByOpts] = useState(initOrder);
  const [dataArray, setDataArray] = useState([]);
  const [pagination, setPagination] = useState(initPage);
  const [totalValues, setTotalValues] = useState(null);
  const [totalWidth, setTotalWidth] = useState(3);
  const { orderBy, orderDir } = orderByOpts;

  const permissionCheck = useMemo(() => getPermissionsChecker(permissions), [ permissions ]);

  const classes = useStyles(props);

  const { fetchData, fetchBaseEntities } = createActions(
    dataProvider,
    resourceStore,
    setResourceStore,
    isDataLoading,
    setIsDataLoading,
    filter,
    setFilter,
    dataArray,
    setDataArray,
    groupByForm,
    setGroupByForm,
    groupByTable,
    setGroupByTable,
    pagination,
    setPagination,
    setTotalValues,
    setTotalWidth,
    orderByOpts,
    setOrderByOpts,
    isLoading,
    setIsLoading,
    props.history,
    props.location
  );

  useEffect(() => {
    if (
      authState.loading ||
      isLoading ||
      isDataLoading ||
      isLoadingPermissions
    ) {
      return;
    }

    if (
      !authState.authenticated ||
      !permissionCheck({ statistics: ['show'] })
    ) {
      logout();
      return;
    }

    if (isInit) {
      return;
    }
    setIsDataLoading(true);
    const promises = [fetchBaseEntities()];

    if (needInitFetch) {
      setIsLoading(true);
      promises.push(fetchData(pagination, orderByOpts));
    }
    Promise.all(promises).then(() => {
      setIsInit(true);
    });
  }, [
    needInitFetch,
    authState.loading,
    authState.authenticated,
    isLoading,
    isInit,
    isLoadingPermissions,
    isDataLoading,
    permissionCheck,
    fetchBaseEntities,
    logout,
    fetchData,
    pagination,
    orderByOpts,
  ]);

  if (
    authState.loading ||
    !authState.authenticated ||
    !isInit ||
    isLoadingPermissions
  ) {
    return <Loading />;
  }
  const createSortHandler = property => event => {
    const isAsc = orderBy === property && orderDir === 'asc';
    fetchData(pagination, {
      orderBy: property,
      orderDir: isAsc ? 'desc' : 'asc',
    });
  };

  const downloadCsvReport = () => {
    const query = stringify({
      filter: {
        ...filter,
        dateFrom: format(filter.dateFrom, 'yyyy-MM-dd'),
        dateTo: format(filter.dateTo, 'yyyy-MM-dd'),
      },
      groupBy: groupByForm,
      orderBy: { ...orderByOpts },
    });

    const reportDate =  format(Date.now(), 'dd-MM-yyyy')
    const fileNameFilters = Object.keys(filter).join('-');

    dataProvider.request(`statistics/download-report${query.length > 0 ? `?${query}` : ''}`)
      .then(({data}) => {
        let url = 'data:text/csv;charset=utf-8,' + encodeURI(data);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${reportDate}_${fileNameFilters}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  return (
    <>
      <Title title="General Statistics" />
      <div>
        <Card className={classes.controlsCard}>
          <CardContent>
            <FormFilter
              filter={filter}
              setFilter={setFilter}
              errors={errors}
              setErrors={setErrors}
              resourceStore={resourceStore}
            />
            <FormGroupBy groupBy={groupByForm} setGroupBy={setGroupByForm} />
          </CardContent>
          <CardActions className={classes.controlsCardActions}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => fetchData({ perPage: pagination.perPage })}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<GetAppIcon />}
              disabled={!Boolean(dataArray.length)}
              onClick={downloadCsvReport}
            >
              Download CSV
            </Button>
          </CardActions>
        </Card>
      </div>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {totalWidth === 0 && <TableCell />}
                {Object.keys(GROUP_FIELD_CONFIG).map(groupField => {
                  if (!groupByTable.includes(groupField)) {
                    return null;
                  }

                  if (GROUP_FIELD_CONFIG[groupField].renderHead) {
                    return GROUP_FIELD_CONFIG[groupField].renderHead({
                      key: groupField,
                      orderBy,
                      orderDir,
                      createSortHandler,
                    });
                  }
                  return (
                    <TableCell
                      key={groupField}
                      sortDirection={orderBy === groupField ? orderDir : false}
                    >
                      <TableSortLabel
                        active={orderBy === groupField}
                        direction={orderBy === groupField ? orderDir : 'asc'}
                        onClick={createSortHandler(groupField)}
                      >
                        {GROUP_FIELD_CONFIG[groupField].title}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === 'requestCount'}
                    direction={orderBy === 'requestCount' ? orderDir : 'asc'}
                    onClick={createSortHandler('requestCount')}
                  >
                    Requests
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === 'impressionCount'}
                    direction={orderBy === 'impressionCount' ? orderDir : 'asc'}
                    onClick={createSortHandler('impressionCount')}
                  >
                    Impressions
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === 'clickCount'}
                    direction={orderBy === 'clickCount' ? orderDir : 'asc'}
                    onClick={createSortHandler('clickCount')}
                  >
                    Clicks
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={orderBy === 'uniqCount'}
                    direction={orderBy === 'uniqCount' ? orderDir : 'asc'}
                    onClick={createSortHandler('uniqCount')}
                  >
                    Uniq
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">CTR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataArray.length === 0 && (
                <TableRow>
                  <TableCell colSpan={groupByTable.length + 5} align="center">
                    No Data
                  </TableCell>
                </TableRow>
              )}
              {dataArray.map((record, idx) => (
                <TableRow key={`data-${idx}`}>
                  {Object.keys(GROUP_FIELD_CONFIG).map(
                    (groupField, gidx) =>
                      groupByTable.includes(groupField) &&
                      GROUP_FIELD_CONFIG[groupField].renderCell({
                        record,
                        store: resourceStore,
                        key: `data-${idx}-${gidx}`,
                      })
                  )}
                  {totalWidth === 0 && <TableCell />}
                  <TableCell align="right">{record.requestCount}</TableCell>
                  <TableCell align="right">{record.impressionCount}</TableCell>
                  <TableCell align="right">{record.clickCount}</TableCell>
                  <TableCell align="right">{record.uniqCount}</TableCell>
                  <TableCell align="right">
                    {calcCtr(record.requestCount, record.clickCount)}%
                  </TableCell>
                </TableRow>
              ))}

              {totalValues && (
                <TableRow>
                  <TableCell colSpan={totalWidth}>Total:</TableCell>
                  {Object.values(totalValues).map((item, idx) => (
                    <TableCell align="right" key={idx}>
                      {item}
                    </TableCell>
                  ))}
                  <TableCell align="right">
                    {calcCtr(totalValues.totalRequest, totalValues.totalClick)}%
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.perPage}
          page={pagination.page - 1}
          onChangePage={(event, newPage) => {
            if (isLoading) {
              return;
            }
            fetchData(
              {
                ...pagination,
                page: newPage + 1,
              },
              orderByOpts
            );
          }}
          onChangeRowsPerPage={event => {
            if (isLoading) {
              return;
            }
            fetchData(
              {
                ...pagination,
                page: 1,
                perPage: Number.parseInt(event.target.value, 10),
              },
              orderByOpts
            );
          }}
        />
      </Paper>
    </>
  );
};

export default GeneralStatistics;
