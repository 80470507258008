import React from 'react';
import { stringify } from 'qs';
import {
  useDataProvider,
  Button,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import FileCopyIcon from '@material-ui/icons/FileCopy';



const ClickUrlModal = ( { id: bannerId, adData }) => {

  const dataProvider = useDataProvider();
  const [open, setOpen] = React.useState(false);
  const [dstUrl, setDstUrl] = React.useState(adData.clickUrl || '');
  const [clickUrl, setClickUrl] = React.useState('');
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const generateClickUrl = () => {
    setIsLoading(true);
    dataProvider.request(`banner/${bannerId}/proxyUrls?${stringify({ dstUrl: dstUrl.trim() })}`)
      .then(res => {
        const { clickUrl } = res.data || {};
        setClickUrl(clickUrl);
      })
      .catch(e => { console.log(e)})
      .finally(() => {
        setIsLoading(false);
      })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onChange = ({ target: { value }}) => {
    setDstUrl(value);
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const afterCopied = () => {
    setShowTooltip(true);
    setTimeout(handleTooltipClose, 750);
  };

  return (
    <>
      <Button variant="outlined" label="Generate Click Url" onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Past destination url, and push icon to generate Click Url
          </DialogContentText>
          <TextField
            variant="filled"
            autoFocus
            margin="dense"
            label="Destination url"
            fullWidth
            value={dstUrl}
            disabled={isLoading}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    title="Generate Click Url"
                    onClick={generateClickUrl}
                  >
                    <ArchiveIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <DialogContentText>
            <br />
            Click Url:
          </DialogContentText>
          <TextField
            variant="filled"
            margin="dense"
            label="Click Url"
            fullWidth
            value={clickUrl}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={showTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied"
                  >
                  <CopyToClipboard text={clickUrl} onCopy={afterCopied}>
                    <IconButton
                      edge="end"
                      title="Copy Click Url"
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
};

export default ClickUrlModal;
