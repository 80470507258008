import React, { useState, useEffect } from 'react';
import {
  useDataProvider,
  Loading,
  Error,
  NumberInput,
  SelectArrayInput,
  RadioButtonGroupInput,
  minValue,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ResettableInput from './ResettableInput';
import RaDatePicker from './RaDatePicker';

const useStyles = makeStyles(theme => ({
  formWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const requestRule = (dataProvider, ruleName) =>
  dataProvider
    .getList(`rules/${ruleName}`, { pagination: {} })
    .then(res => {
      const { data = []} = res || {};
      return data;
    });

const validateEndDate = (value, formData) => {
  // если не установлена дата конца - это норма, значит кампания бесконечна
  if (!value) {
    return;
  }

  // а вот если при установленном конце не установлено начало - это ошибка
  if (!formData.dateStart) {
    return 'Start date should be set with end date';
  }
  const dateStart = new Date(formData.dateStart);
  const dateEnd = new Date(value);
  // так же ошибка если начало позже конца
  if (dateStart > dateEnd) return 'End date should come after start date';
};

const validateGeoListType = (isOut, formData) => {
  const { country } = formData.targetingRules || {};
  const { list = []} = country || {};

  if (list.length > 0 && (typeof isOut !== 'boolean')) {
    return 'Must choose list type White or Black';
  }

  if (list.length === 0 && (typeof isOut === 'boolean')) {
    return 'Type can\'t set for empty list';
  }
};

const TargetingSelect = props => {
  const dataProvider = useDataProvider();
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const [os, setOs] = useState();
  const [browsers, setBrowsers] = useState();
  const [locales, setLocales] = useState();
  const [countries, setCountries] = useState();
  const classes = useStyles(props);

  useEffect(() => {
    if (isInit) {
      return;
    }
    setIsInit(true);

    Promise.all([
      requestRule(dataProvider, 'os'),
      requestRule(dataProvider, 'browser'),
      requestRule(dataProvider, 'locale'),
      requestRule(dataProvider, 'country'),
    ])
      .then(([osList, browserList, localeList, countryList]) => {
        setOs(osList);
        setBrowsers(browserList);
        setLocales(localeList);
        setCountries(countryList);
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
        setError(e);
      });
  }, [dataProvider, isInit]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <div className={classes.formWrap}>
      {/* <NumberInput
        label="Day Limit"
        source={`${sourceField}.dayLimit`}
        min={0}
        validate={minValue(0)}
        {...props}
      />
      <NumberInput
        label="Overall Limit"
        source={`${sourceField}.overallLimit`}
        min={0}
        validate={minValue(0)}
        {...props}
      />*/}
      <ResettableInput>
        <RaDatePicker
          label="Date start"
          source="dateStart"
          {...props}
          clearable={true}
        />
      </ResettableInput>
      <ResettableInput>
        <RaDatePicker
          label="Date end"
          source="dateEnd"
          validate={validateEndDate}
          {...props}
          clearable={true}
        />
      </ResettableInput>
      <ResettableInput>
        <RadioButtonGroupInput
          label="Device Type"
          source="targetingRules.device"
          translateChoice={false}
          choices={[
            { id: 'mobile', name: 'Mobile' },
            { id: 'desktop', name: 'Desktop' },
          ]}
          {...props}
        />
      </ResettableInput>
      <SelectArrayInput
        label="OS"
        source="targetingRules.os.list"
        choices={os}
        optionText="id"
        {...props}
      />
      <SelectArrayInput
        label="Browser"
        source="targetingRules.browser.list"
        choices={browsers}
        optionText="id"
        {...props}
      />
      <SelectArrayInput
        label="Locales"
        source="targetingRules.locale.list"
        choices={locales}
        optionText="id"
        {...props}
      />

      <ResettableInput source="targetingRules.country">
        <RadioButtonGroupInput
          label="GEO"
          source="targetingRules.country.isOut"
          translateChoice={false}
          validate={validateGeoListType}
          choices={[
            { id: false, name: 'Whitelist' },
            { id: true, name: 'Blacklist' },
          ]}
          {...props}
        />
      </ResettableInput>

      <SelectArrayInput
        label="Countries"
        source="targetingRules.country.list"
        choices={countries}
        optionText="id"
        {...props}
      />
    </div>
  );
};

export default TargetingSelect;
