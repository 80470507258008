import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

const BannerListFilter = props => {
  return (
    <Filter {...props}>
      <ReferenceInput
        label="Banner Zone"
        source="bannerZone.id"
        reference="banner_zone"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Site"
        source="bannerZone.siteId"
        reference="site"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Campaign"
        source="campaign.id"
        reference="campaigns"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Advertiser"
        source="campaign.advertiserId"
        reference="advertisers"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

export default BannerListFilter;
