import React, { useMemo } from 'react';
import { Chip, TextField } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { applyFiltersEntities } from '../../services/utils';

const useStyles = makeStyles(theme => ({
  selectedValues: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const SelectEntity = ({
  entity,
  title,
  list = [],
  filter = {},
  selected = [],
  setSelected = () => {},
  ...props
}) => {
  const classes = useStyles(props);
  const filteredList = useMemo(() => {
    if (!filter) {
      return list;
    }
    return Object.values(applyFiltersEntities(list, filter)).map(
      ({ id }) => id
    );
  }, [list, filter]);
  const filterOptions = useMemo(
    () => createFilterOptions({ stringify: option => list[option].name }),
    [list]
  );

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      fullWidth
      limitTags={3}
      className={classes.select}
      options={filteredList}
      value={selected}
      filterOptions={filterOptions}
      onChange={(event, newValue) => {
        setSelected(newValue);
      }}
      renderOption={(option, state) => list[option].name}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          label={title}
          placeholder={title}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            size="small"
            label={list[option].name}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};

export default SelectEntity;
