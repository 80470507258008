import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IconButton, Tooltip } from '@material-ui/core';
import { useNotify } from 'react-admin';

export const copyTextToClipboard = text => {
  var textArea = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  let res = false;

  try {
    res = document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy text to clipboard', err);
  }
  document.body.removeChild(textArea);
  return res;
};

export const CopyToClipboardButton = ({
  size = 'small',
  text,
  doNotify = true,
  ...props
}) => {
  const notify = useNotify();
  return (
    <Tooltip title="Copy to clipboard">
      <IconButton
        {...props}
        size={size}
        onClick={e => {
          e.stopPropagation();
          const res = copyTextToClipboard(text);

          if (!doNotify) {
            return;
          }

          if (res) {
            notify('Copied to clipboard', 'info', {}, false, 1000);
          } else {
            notify('Failed to copy to clipboard', 'warning', {}, false, 1000);
          }
        }}
      >
        <FileCopyIcon fontSize={size} />
      </IconButton>
    </Tooltip>
  );
};
