import React from 'react';
import { useInput, InputHelperText } from 'react-admin';
import { DateTimePicker, DatePicker, TimePicker } from '@material-ui/pickers';
import { DATETIME_FORMAT } from '../../../config';

const DATE = 'date';
const TIME = 'time';
const DATETIME = 'datetime';

const TYPE = {
  [DATE]: DatePicker,
  [TIME]: TimePicker,
  [DATETIME]: DateTimePicker,
};

const [dateFormat, timeFormat] = DATETIME_FORMAT.split(' ');

const FORMAT = {
  [DATE]: dateFormat,
  [TIME]: timeFormat,
  [DATETIME]: DATETIME_FORMAT,
};

const RaPicker = ({ type = DATETIME, format = FORMAT[type], ...props }) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput(props);

  const Picker = TYPE[type];

  // Очень нужная хрень, потому что иначе пустая строка инициализирует инпут, и он начинает думать что он не state ориентированный компонент
  rest.value = rest.value || null;

  return (
    <Picker
      margin="dense"
      variant="dialog"
      ampm={false}
      name={name}
      label={props.label}
      className={props.className || ''}
      onChange={onChange}
      error={!!(touched && error)}
      clearable={props.clearable}
      helperText={
        <InputHelperText
          touched={touched}
          error={error}
          helperText={(touched && error) || (<span>&#8203;</span>)}
        />
      }
      required={isRequired}
      {...rest}
      disabled={props.disabled}
      inputVariant="filled"
    />
  )
};

export default RaPicker;
