import React, { useState } from 'react';
import {
  Create,
  FileField,
  FileInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { redirect as redirectHelper } from '../../services/utils';
import { maxTextLength } from '../../services/validtors';
import { createFile } from '../../services/FileService';
import FormActions from '../common/FormActions';

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine || props.isLoading} />
  </Toolbar>
);

const WrappedSimpleForm = ({ save, customSave, ...rest }) => {
  return <SimpleForm save={customSave} {...rest} />;
};

const FileCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);

  const save = async data => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const { sourceFile, ...restData } = data;
      const fileData = await createFile(
        {
          ...sourceFile,
          ...restData,
        },
        dataProvider
      );
      redirect(redirectHelper.toShow('/file', fileData.id));
    } catch (e) {
      notify(e.message, 'warning');
      console.error(e);
    }
    setIsLoading(false);
  };
  return (
    <Create {...props} actions={<FormActions />}>
      <WrappedSimpleForm
        customSave={save}
        toolbar={<CustomToolbar isLoading={isLoading} />}
      >
        <TextInput
          source="title"
          helperText="Set to file name if not specified"
          validate={[maxTextLength()]}
          disabled={isLoading}
        />
        <FileInput
          source="sourceFile"
          label="Source File"
          validate={[required()]}
          disabled={isLoading}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </WrappedSimpleForm>
    </Create>
  );
};

export default FileCreate;
