import React from 'react';
import { Resource } from 'react-admin';
import BannerZoneList from './List';
import BannerZoneEdit from './Edit';
import BannerZoneCreate from './Create';
import BannerZoneShow from './Show';
import AssessmentIcon from '@material-ui/icons/Assessment';

/**
 * @param {*} permissionCheck
 * @returns {*}
 */
const BannerZoneResource = permissionCheck => {
  if (!permissionCheck({ banner_zone: ['show'] })) {
    return null;
  }
  return (
    <Resource
      name="banner_zone"
      show={BannerZoneShow}
      list={permissionCheck({ banner_zone: ['list'] }) ? BannerZoneList : null}
      create={
        permissionCheck({ banner_zone: ['create'] }) ? BannerZoneCreate : null
      }
      edit={permissionCheck({ banner_zone: ['edit'] }) ? BannerZoneEdit : null}
      icon={AssessmentIcon}
    />
  );
};

export default BannerZoneResource;
