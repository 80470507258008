import React from 'react';
import { Resource } from 'react-admin';
import Create from './Create';
import List from './List';
import Show from './Show';
import AttachFileIcon from '@material-ui/icons/AttachFile';

/**
 * @param {*} permissionCheck
 * @returns {*}
 */
const FileResource = permissionCheck => {
  if (!permissionCheck({ file: ['show'] })) {
    return null;
  }
  return (
    <Resource
      name="file"
      show={Show}
      list={permissionCheck({ file: ['list'] }) ? List : null}
      create={permissionCheck({ file: ['create'] }) ? Create : null}
      icon={AttachFileIcon}
    />
  );
};

export default FileResource;
