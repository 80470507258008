import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  DeleteButton,
  DateField
} from 'react-admin';
import ZonesListActions from './ListActions';
import ZonesListFilter from './ListFilter';
import TargetingSummaryField from '../common/fields/TargetingSummary';
import CustomPagination from '../common/inputs/CustomPagination';

const RowActions = ({...props }) => (
  <div style = {{ textAlign: 'right' }}>
    <EditButton {...props} undoable={false} />
    <DeleteButton {...props} undoable={false} />
  </div>
);

const CampaignList = props => {
  return (
    <List {...props} pagination={<CustomPagination />} actions={<ZonesListActions />} filters={<ZonesListFilter/>}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" fullWidth />
        <ReferenceField label="Advertiser" source="advertiserId" reference="advertisers" sortBy="advertiserName">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="dateStart" showTime label="Campaign start" />
        <DateField source="dateEnd" showTime label="Campaign end"/>
        <TargetingSummaryField label="Targets" sortable={false} />
        <RowActions />
      </Datagrid>
    </List>
  );
};

export default CampaignList;
