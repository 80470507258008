import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import FormActions  from '../common/FormActions';
import { maxTextLength } from '../../services/validtors';

const SiteEdit = props => {
  return (
    <Edit undoable={false} actions={<FormActions isShow={false} />} {...props}>
      <SimpleForm>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" validate={[required(), maxTextLength()]} />
      </SimpleForm>
    </Edit>
  );
};

export default SiteEdit;
