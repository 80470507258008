import React  from 'react';
import { Create, SimpleForm, TextInput, required, maxLength, ReferenceInput, SelectInput } from 'react-admin';
import { parse } from 'query-string';
import { parentConfig } from './config';
import FormActions from './FormActions';

const NotesCreate = props => {
  const { 'filter[parentId]': parentIdStr, 'filter[parentType]': parentType } = parse(props.location.search);

  if (!parentConfig[parentType]) {
    return null;
  }
  const parentId = Number(parentIdStr) || null;

  const { titleField = 'name', label = 'parent' } = parentConfig[parentType];

  return (
    <Create {...props} actions={<FormActions basePath={`/${parentType}/${parentId}/show`} />}>
      <SimpleForm
        initialValues={{
          parentId,
          parentType
        }}
        redirect={`/${parentType}/${parentId}/show`}
      >
        <ReferenceInput
          source="parentId"
          reference={parentType}
          allowEmpty
          validate={required()}
          disabled
          label={label}
        >
          <SelectInput optionText={titleField} />
        </ReferenceInput>

        <TextInput multiline source="text" validate={[required(), maxLength(254, 'It must be short note (254 symbols only)')]} />
      </SimpleForm>
    </Create>
  );
};

export default NotesCreate;
