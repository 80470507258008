import React  from 'react';
import { Edit, SimpleForm, TextInput, required, email, ArrayInput, SimpleFormIterator } from 'react-admin';
import FormActions from '../common/FormActions';
import { redirect } from '../../services/utils';
import {httpInput, maxTextLength} from '../../services/validtors';
import ContactsSubForm from './ContactsSubForm';

const Title = ({ record }) => {
  return <span>Advertiser: {record ? `"${record.name}"` : ''}</span>;
};

const AdvertiserEdit = props => {
  return (
    <Edit {...props} undoable={false} title={<Title />} actions={<FormActions />}>
      <SimpleForm redirect={redirect.toShow}>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" validate={[required(), maxTextLength()]} />
        <TextInput source="dashboard" validate={[httpInput(), maxTextLength(250)]} />
        <ContactsSubForm />
      </SimpleForm>
    </Edit>
  );
};

export default AdvertiserEdit;
