import React  from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

const rowRender = (record, index) => {
  const { name, jobName, email, skype } = record || {};
  return (<TableRow key={index}>
    <TableCell>
      <div>{name}</div>
      <Typography variant="caption" style={{ opacity: '.54' }}>{jobName}</Typography>
    </TableCell>
    <TableCell>{email}</TableCell>
    <TableCell>{skype}</TableCell>
    {/*<TableCell>
      <IconButton aria-label="edit">
        <EditIcon/>
      </IconButton>
      <IconButton aria-label="delete">
        <DeleteIcon/>
      </IconButton>
    </TableCell>*/}
  </TableRow>)
};

const Contacts = ({ className, record }) => (
  <Table size="small" className={className}>
    <TableHead>
      <TableRow>
        <TableCell>Manager</TableCell>
        <TableCell>Email</TableCell>
        <TableCell>Skype</TableCell>
        {/*<TableCell>Actions</TableCell>*/}
      </TableRow>
    </TableHead>

    <TableBody>
      {(record.contacts || []).map(rowRender)}
    </TableBody>
  </Table>
);

export default Contacts;
