import React from 'react';
import { ListButton, ShowButton, TopToolbar } from 'react-admin';

const FormActions = ({ basePath, data, isList = true, isShow = true }) => {
  return (
    <TopToolbar>
      {isList && <ListButton basePath={basePath} />}
      {isShow && data && <ShowButton basePath={basePath} record={data} />}
    </TopToolbar>
  )
};

export default FormActions;
