import { UNIVERSAL_ACCESS } from '../config'

export const getPermissionsChecker = userPermissions => requiredPermissions => {
  if (userPermissions === UNIVERSAL_ACCESS || !requiredPermissions) {
    return true;
  }

  return userPermissions && Object.keys(requiredPermissions).every(entityName => {
    if (userPermissions[entityName] === UNIVERSAL_ACCESS) {
      return true;
    }
    return (
      userPermissions[entityName] &&
      requiredPermissions[entityName].every(action =>
        userPermissions[entityName].includes(action)
      )
    );
  });
};
