import {DEFAULT_GROUP_BY, SEARCH_FIELD_CONFIG, GROUP_FIELD_CONFIG} from './FieldConfig';
import {parse, stringify} from 'qs';
import {format, startOfDay} from 'date-fns';

export const calcCtr = (imp, click) =>
  !imp || !click ? 0 : Math.round((click / imp) * 100000) / 1000;

export const getInitialSearchState = location => {
  const needInitFetch = location.search !== '';
  const {
    filter = {},
    groupBy = DEFAULT_GROUP_BY,
    pagination,
    orderBy = {},
  } = parse(location.search.substr(1));

  const pFilter = Object.keys(filter).reduce(
    (container, field) => {
      if (field in SEARCH_FIELD_CONFIG) {
        return SEARCH_FIELD_CONFIG[field].reducer(
          container,
          field,
          filter[field]
        );
      }
      return container;
    },
    {
      dateFrom: startOfDay(new Date()),
      dateTo: startOfDay(new Date()),
    }
  );

  const pPagination = {
    page: 1,
    perPage: 50,
    count: 0,
  };

  if (pagination) {
    const { page, perPage } = pagination;
    const nPage = Number.parseInt(page, 10);
    const nPerPage = Number.parseInt(perPage, 10);

    if (nPage > 0) {
      pPagination.page = nPage;
    }

    if (nPerPage > 0) {
      pPagination.perPage = nPerPage;
    }
  }

  return [needInitFetch, pFilter, groupBy, orderBy, pPagination];
};

export const createActions = (
  dataProvider,
  resourceStore,
  setResourceStore,
  isDataLoading,
  setIsDataLoading,
  filter,
  setFilter,
  dataArray,
  setDataArray,
  groupByForm,
  setGroupByForm,
  groupByTable,
  setGroupByTable,
  pagination,
  setPagination,
  setTotalValues,
  setTotalWidth,
  orderByOpts,
  setOrderByOpts,
  isLoading,
  setIsLoading,
  history,
  location
) => ({
  fetchBaseEntities: () => {
    if (isDataLoading) {
      return;
    }
    setIsDataLoading(true);
    return Promise.all(
      ['advertisers', 'campaigns', 'banner', 'site', 'banner_zone', 'os', 'browser', 'device_type'].map(
        resource => {
          const resUrl = [ 'os', 'browser', 'device_type'].includes(resource) ? `statistics/${resource}` : resource
          return dataProvider
            .getList(resUrl, { pagination: {} })
            .then(({ data }) => {
              return [
                resource,
                data.reduce(
                  (list, entity) => ({
                    ...list,
                    [entity.id]: entity,
                  }),
                  {}
                ),
              ]})
            .catch(e => {
              console.log(e);
              return [resource, {}];
            });
        }
      )
    )
      .then(allRes => {
        const newStore = allRes.reduce(
          (data, [resource, list]) => ({
            ...data,
            [resource]: list,
          }),
          resourceStore
        );
        setResourceStore(newStore);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  },
  fetchData: (reqPagination, reqOrderBy) => {
    if (isLoading) {
      return;
    }
    const query = stringify({
      filter: {
        ...filter,
        dateFrom: format(filter.dateFrom, 'yyyy-MM-dd'),
        dateTo: format(filter.dateTo, 'yyyy-MM-dd'),
      },
      groupBy: groupByForm,
      orderBy: { ...orderByOpts, ...(reqOrderBy || {}) },
      pagination: { ...(reqPagination || {}) },
    });
    const url = `statistics/general${query.length > 0 ? `?${query}` : ''}`;

    setIsLoading(true);
    return dataProvider
      .request(url)
      .then(res => {
        if (!res.data) {
          return;
        }
        setTotalWidth(groupByForm.reduce((sum, field) => {
          return sum + (
            GROUP_FIELD_CONFIG[field].fieldsCount
              ? GROUP_FIELD_CONFIG[field].fieldsCount
              : 1
          );
        }, 0))
        setGroupByTable(groupByForm);
        setPagination({
          ...pagination,
          ...(res.pagination || {}),
        });
        setTotalValues(res.total)
        setOrderByOpts({
          ...orderByOpts,
          ...(reqOrderBy || {}),
        });
        history.push({
          search: query.length > 0 ? `?${query}` : '',
        });
        setDataArray(res.data)
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  },
});
