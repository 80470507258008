import React from 'react';
import {
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  CreateButton,
} from 'react-admin';

const AdvertisersListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;

  const { basePath } = useListContext();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default AdvertisersListActions;
