import React from 'react';
import { Labeled } from 'react-admin';
import { CheckCircleRounded, Cancel } from '@material-ui/icons';

const CustomBoolField = ({status, label}) => {
  return <Labeled label={label}>
    { status ? <CheckCircleRounded style={{color: 'green'}} /> : <Cancel style={{color: 'red'}} /> }
  </Labeled>
};

export default CustomBoolField;
