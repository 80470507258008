import JWTManager from '../services/JWTManager';
import httpClient from './DataProvider/httpClient';
import { API_URL } from '../config';

// uncomment for dev only
// window.JWTManager = JWTManager;

JWTManager.setRefreshTokenUrl(API_URL + '/auth/refresh-token');

function authProvider() {
  let permissions;

  return {
    login: ({ username, password }) => {
      permissions = null;

      return httpClient(API_URL + '/auth/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      }).then(({ token, expiresIn }) => JWTManager.setToken(token, expiresIn));
    },
    logout: () => {
      permissions = null;
      JWTManager.clearToken();

      httpClient(API_URL + '/auth/logout', {
        method: 'GET',
        credentials: 'include',
      })
        .then(() => {})
        .catch(() => {});

      return Promise.resolve();
    },
    checkAuth: () => {
      return JWTManager.getInitToken().then(token =>
        token ? Promise.resolve() : Promise.reject({ redirectTo: '/login' })
      );
    },
    checkError: ({ status }) => {
      if (status === 401 || status === 403) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    getPermissions: () => {
      if (permissions) {
        return Promise.resolve(permissions);
      }
      return JWTManager.getInitToken().then(token => {
        if (!token) {
          throw new Error('Unauthorized');
        }
        return httpClient(API_URL + '/user/permissions', {
          method: 'GET',
          credentials: 'include',
          headers: {
            authorization: `Bearer ${token}`,
          },
        }).then(({ data })=> {
          permissions = data;
          return permissions;
        });
      });
    },
    // Not implemented in ra-admin 3.8
    getIdentity: () => Promise.resolve(),
  };
}

export default authProvider();
