import React, { cloneElement } from 'react';
import {
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  CreateButton,
} from 'react-admin';

const BannerListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;

  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export default BannerListActions;
