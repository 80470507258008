import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  card: {
    marginBottom: '1em',
  },
  infoButtons: {
    float: 'right',
  },
  infoBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '560px',
    justifyContent: 'space-between',
  },
  infoBoxColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '16px',
    minWidth: '128px',
    maxWidth: '256px',
  },
  subTitle: {
    margin: '20px 0 15px',
  },
  subTitleFirst: {
    margin: '0 0 15px',
  },
  subBlock: {
    marginBottom: '20px',
  },
});
