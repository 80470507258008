import React  from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import FormActions from '../common/FormActions';
import { redirect } from '../../services/utils';
import { maxTextLength } from '../../services/validtors';


const BannerZoneEdit = props => {
  return (
    <Edit {...props} undoable={false} actions={<FormActions />}>
      <SimpleForm redirect={redirect.toShow}>
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" validate={[required(), maxTextLength()]} />
        <ReferenceInput label="Site" source="siteId" reference="site" validate={required()} >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default BannerZoneEdit;
