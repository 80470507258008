import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider, Popper, Paper, Fade} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
  ChipField
} from 'react-admin';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  fieldWrap: {
    maxWidth: '250px'
  },
}));

const usePopperStyles = makeStyles(theme => ({
  popper: {
    zIndex: 9,
  },
  popperBody: {
    padding: '10px 15px',
    maxWidth: '200px',
  },
  akaNotClick: {
    backgroundColor: '#e0e0e0 !important',
    cursor: 'default !important',
  },
  targetFullInfo: {
    '&:not(:last-child)': {
      marginBottom: '10px'
    }
  }
}));

// const TargetWithValues = ({ rule, values, prefixInfo })  => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [open, setOpen] = useState(false);
//   const classes = usePopperStyles({});
//
//   const stopPropagation = event => {
//     event.stopPropagation();
//   };
//
//   const handleClick = event => {
//     stopPropagation(event);
//     setAnchorEl(event.currentTarget);
//     setOpen(isOpen => !isOpen);
//   };
//   const handleClickAway = () => {
//     setOpen(false);
//   };
//
//   return (
//     <>
//       {!!values &&
//         <Popper
//           open={open}
//           anchorEl={anchorEl}
//           placement="bottom-start"
//           className={classes.popper}
//           onClick={stopPropagation}
//           transition
//         >
//           {({ TransitionProps }) => (
//             <ClickAwayListener onClickAway={handleClickAway}>
//               <Fade {...TransitionProps} timeout={250}>
//                 <Paper elevation={3} className={classes.popperBody}>
//                   {prefixInfo && <span style={{ marginRight: '5px'}}>{prefixInfo}</span>} {Array.isArray(values) && values.join(', ')}
//                 </Paper>
//               </Fade>
//             </ClickAwayListener>
//           )}
//         </Popper>
//       }
//       {/*
//         каждый ChipField clickable=true, для того чтоб можно было перехватить нажатие на него и прервать событие клика по строке таблицы, с дальнейшим переходом на
//         странцу редактирования или просмотра сущности. При этом некоторые чипы не должны ВЫГЛЯДЕТЬ как кликабельные, для этого навешиваем класс akaNotClick
//       */}
//       <ChipField source="rule" record={{ rule }} onClick={handleClick} clickable={true} className={!values ? classes.akaNotClick : ''}/>
//     </>
//   )
// };

const TargetRuleInfo = ({ rule, values, prefixInfo }) => {
  const classes = usePopperStyles();
  return (
    <div className={classes.targetFullInfo}>
      <Typography variant="subtitle2">{rule}{prefixInfo && <span style={{ marginLeft: '5px'}}>({prefixInfo})</span>}:</Typography>
      <div>
        {values.join(', ')}
      </div>
    </div>
  )
};

const TargetingSummaryField = props => {
  const { record: { targetingRules = {} } } = props;
  const classes = useStyles(props);
  const rules = ['os', 'browser', 'locale', 'country'].filter(
    key => {
      const { list } = targetingRules[key] || {};
      return Array.isArray(list) && list.length > 0
    }
  );

  const scalarRules = ['device'].filter(key => !!targetingRules[key]);

  const combine = rules.map(key => ({
    key,
    values: targetingRules[key].list,
  }));

  combine.push(
    ...scalarRules.map(key => ({
      key,
      values: [targetingRules[key]],
    }))
  );

  const { country } = targetingRules;
  const { isOut: isCountryBlackList } = country || {};

  return <div className={classes.fieldWrap}>{combine.length === 0
    ? 'Not set'
    : combine.map(({ key: rule, values }, id) =>
      <TargetRuleInfo
        rule={rule}
        values={values}
        key={`${id}_${rule}`}
        prefixInfo={rule === 'country' ? (isCountryBlackList ? 'Black list' : 'White list') : ''}
      />
    )
  }</div>;
};

TargetingSummaryField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
};

export default TargetingSummaryField;
