import React, { cloneElement } from 'react';
import { Filter, TextInput } from 'react-admin';
//import Typography from '@material-ui/core/Typography';

const AdvertisersListFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Name" source="name" alwaysOn />
    </Filter>
  );
};

export default AdvertisersListFilter;
