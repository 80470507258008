import React from 'react';
import { Admin } from 'react-admin';
import advertisersInjector from './components/Advertisers/Resource';
import bannerInjector from './components/Banner/Resource';
import bannerZoneInjector from './components/BannerZone/Resource';
import notesInjector from './components/Notes/Resource';
import campaignsInjector from './components/Campaigns/Resource';
import siteInjector from './components/Site/Resource';
import fileInjector from './components/File/Resource';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Layout from './Layout';
//
import authProvider from './providers/AuthProvider';
import dataProvider from './providers/DataProvider';
import customRoutes from './customRoutes';
import { getPermissionsChecker } from './services/Permissions'

import Dashboard from './components/Dashboard';

const App = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Admin dashboard={Dashboard} layout={Layout} dataProvider={dataProvider} authProvider={authProvider} customRoutes={customRoutes}>
        {permissions => {
          const permissionCheck = getPermissionsChecker(permissions);
          return [
            advertisersInjector(permissionCheck),
            siteInjector(permissionCheck),
            bannerZoneInjector(permissionCheck),
            campaignsInjector(permissionCheck),
            bannerInjector(permissionCheck),
            notesInjector(permissionCheck),
            fileInjector(permissionCheck),
            // Странный, но вцелом нужный костыль. Admin необходим хотя бы 1 ресурс
            // если все выше вернет null, вне зависимости от того есть ли кастомные  страницы,
            // админка не будет грузиться. При таком варианте админка загружается, и возможно
            // посетить кастомные страницы.
            [[]]
          ]
        }}
      </Admin>
    </MuiPickersUtilsProvider>
  )
};

export default App;
