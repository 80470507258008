import React, { useState } from 'react';
import { NumberInput, useInput } from 'react-admin';
import { IconButton, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import CustomSelect from './CustomSelect';

const useStyles = makeStyles(theme => ({
  px: {
    fontSize: '.6em',
    textTransform: 'uppercase',
  },
  input: {
    '& input': {
      '-moz-appearance': 'textfield',
    },
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    }
  }
}));

export const DIGIT_TYPE = {
  PX: 'px',
  '%': '%',
};

const DIGIT_TYPES_LIST = [
  {
    id: DIGIT_TYPE.PX,
  },
  {
    id: DIGIT_TYPE['%'],
  },
];

const isEmpty = v => v !== 0 && !v;


const StyleSizeInput = ({ onChange, ...props }) => {
  const {
    input: {  value: initInputValue },
  } = useInput(props);
  const form = useForm(props);
  const classes = useStyles(props);
  let currentDigitType = DIGIT_TYPES_LIST[0].id;

  // форма почему-то позже инициализируется
  const {value: fullValue } = form.getFieldState(props.source) || { value: initInputValue };
  if (!isEmpty(fullValue)) {
    const num = parseFloat(fullValue);
    currentDigitType = fullValue.replace(new RegExp(`^${num}`), '') || currentDigitType;
  }

  const [digitType, setDigitType] = useState(currentDigitType);
  // когда руками стираешь поле - fullValue === null (parse возвращает),
  // а когда через resettable обнуляется все поле styles, Конкретно styles.field становится undefined
  // сделал чтоб digitType сбрасывался в дефолтный, токль когда clear'ится все поле, а не просто стирается последний символ
  if (currentDigitType !== digitType && (typeof fullValue === 'undefined')) {
    setDigitType(currentDigitType);
    onChange && onChange(fullValue);
  }

  return (
    <NumberInput
      className={classes.input}
      {...props}
      onChange={event => {
        onChange && onChange(`${event.target.value}${digitType}`)
      }}
      format={v => {
        if (isEmpty(v)) return null;
        return parseFloat(v);
      }}
      parse={v => {
        if (isEmpty(v)) return null;
        return `${v}${digitType}`;
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CustomSelect
              optionText="id"
              value={digitType}
              disabled={props.disabled}
              onChange={digitType => {
                setDigitType(digitType);
                if (!isEmpty(fullValue)) {
                  const num = parseFloat(fullValue);
                  const newSize = `${num}${digitType}`;
                  form.change(props.source, newSize);
                  onChange && onChange(newSize)
                }
              }}
              choices={DIGIT_TYPES_LIST}
            >
              <IconButton
                edge="end"
                disabled={props.disabled}
              >
                <span className={classes.px}>{digitType}</span>
              </IconButton>
            </CustomSelect>
          </InputAdornment>
        )
      }}
    />)
};

export default StyleSizeInput;
