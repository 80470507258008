import React  from 'react';
import { SimpleShowLayout, EditButton, DeleteButton, DateField, UrlField } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Card } from '@material-ui/core';
import CircleStatus from '../../common/CircleStatus';

const Title = ({ record = {} }) => {
  return <><Typography component="h5" variant="h5">
    <CircleStatus source="id" record={record} style={{ marginRight: '5px' }} /> {record.name}
  </Typography></>
}

const Header = props => {
 return (<>
    <Actions {...props} />
    <Title {...props} />

    <Typography variant="caption" style={{ opacity: '.54' }}>
      Created at <DateField source="createdAt" {...props} style={{ fontSize: 'inherit' }} />
    </Typography>
    <Typography noWrap>
      Dashboard: <UrlField source="dashboard" target="_blank" emptyText="-" {...props} />
    </Typography>
 </>)
}

const Actions = props => {
  return (
    <div style={{ float: 'right', marginLeft: '5px' }}>
      <EditButton style={{ minWidth: 0, marginRight: '5px' }} title="Edit" label="" {...props} />
      <DeleteButton style={{ minWidth: 0 }} title="Delete"  label="" {...props} undoable={false} />
    </div>
  )
}

const CardInfo = ({ className, style, ...props }) => {
  return (
    <div className={className} style={{ maxWidth: '500px', ...style }}>
      <Card variant="outlined">
        <SimpleShowLayout {...props}>
          <Header {...props} />
        </SimpleShowLayout>
      </Card>
    </div>
  )
};

export default CardInfo;
