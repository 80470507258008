import React  from 'react';
import { Create, SimpleForm, TextInput, required, ReferenceInput, SelectInput } from 'react-admin';
import FormActions from '../common/FormActions';
import { redirect } from '../../services/utils';
import { maxTextLength } from '../../services/validtors';


const BannerZoneCreate = props => {
  return (
    <Create {...props} actions={<FormActions />}>
      <SimpleForm redirect={redirect.toShow}>
        <TextInput source="name" validate={[required(), maxTextLength()]} />
        <ReferenceInput label="Site" source="siteId" reference="site" validate={required()} >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default BannerZoneCreate;
