import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
} from 'react-admin';
import ZonesListActions from './ListActions';
import CustomPagination from '../common/inputs/CustomPagination';

const RowActions = props => (
  <div style={{ textAlign: 'right' }}>
    <EditButton {...props} />
    <DeleteButton undoable={false} {...props} />
  </div>
);

const SiteList = props => {
  return (
    <List {...props} pagination={<CustomPagination />} actions={<ZonesListActions />} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" fullWidth />
        <RowActions />
      </Datagrid>
    </List>
  );
};

export default SiteList;
