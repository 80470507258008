import React from 'react';
import { FunctionField, NumberField, Labeled } from 'react-admin';
import { Box } from '@material-ui/core';
import { EVENT_TYPE_TITLE } from './config';
import useStyles from '../common/styles/show';
import ResetCountersButton from './ResetCountersButton';

const limitsRenderFunc = (limitField, countersField) => record => {
  if (!record.limitEventType || record[limitField] === 0) {
    return 'Not set';
  }
  const curVal =
    record[countersField] && record[countersField].length > 0
      ? record[countersField][0].value
      : 0;
  return `${curVal}/${record[limitField]}`;
};

const fCapRender = limitField => record => {
  if (!record.limitEventType || record[limitField] === 0) {
    return 'Not set';
  }
  return record[limitField];
};

const ShowLimits = props => {
  const classes = useStyles(props);

  return (
    <Box className={classes.infoBoxContainer}>
      <Box className={classes.infoBoxColumn}>
        <Labeled label="Delivery option">
          <FunctionField
            label="Delivery option"
            render={() => !props.record.limitEventType ? '-' : props.record.deliveryOption}
            {...props}
          />
        </Labeled>
        <Labeled label="Limit event type">
          <FunctionField
            render={({ limitEventType }) =>
              EVENT_TYPE_TITLE[limitEventType] || 'No limit'
            }
            {...props}
          />
        </Labeled>
        <Labeled label="Total limit">
          <FunctionField
            render={limitsRenderFunc('totalLimit', 'bannerTotalCounters')}
            {...props}
          />
        </Labeled>
        <Labeled label="Daily limit">
          <FunctionField
            label="Daily limit"
            render={limitsRenderFunc('dailyLimit', 'bannerDailyCounters')}
            {...props}
          />
        </Labeled>
        <Labeled label="Frequency Cap">
          <FunctionField
            label="Daily limit"
            render={fCapRender('frequencyCap')}
            {...props}
            />
        </Labeled>
        <div className={classes.subBlock}>
          {props.record.limitEventType && (
            <ResetCountersButton bannerId={props.record.id} />
          )}
        </div>
      </Box>
    </Box>
  );
};

export default ShowLimits;
