import React from 'react';
import { SelectInput, NumberInput } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import ResettableInput from '../../common/inputs/ResettableInput';
import { EVENT_TYPES, EVENT_TYPE_TITLE } from '../config';

const validateLimit = (value, formData) => {
  if (!/^\d+$/.test(value)) {
    return 'Must be a positive integer';
  }
};

const LimitsSelect = ({ className, ...rest }) => {
  const form = useForm();
  const { values: formValues } = useFormState();

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}
      className={className}
    >
      <SelectInput
        source="deliveryOption"
        resettable
        emptyValue={'ASAP'}
        emptyText="ASAP"
        choices={[
          { id: 'ASAP', name: 'ASAP' },
          { id: 'EVENLY ACROSS THE DAY', name: 'EVENLY ACROSS THE DAY' },
        ]}
        className="red-colored"
        helperText="*If you change delivery option of the working banner, all its counters will automatically reset"
      />
      <SelectInput
        source="limitEventType"
        resettable
        allowEmpty
        emptyValue={null}
        emptyText="No Limit"
        choices={[
          { id: EVENT_TYPES.REQUEST, name: EVENT_TYPE_TITLE[EVENT_TYPES.REQUEST] },
          { id: EVENT_TYPES.IMPRESSION, name: EVENT_TYPE_TITLE[EVENT_TYPES.IMPRESSION] },
          { id: EVENT_TYPES.CLICK, name: EVENT_TYPE_TITLE[EVENT_TYPES.CLICK] },
        ]}
        onChange={e => {
          if (e && e.target && e.target.value) {
            return;
          }
          form.batch(() => {
            form.change('totalLimit', 0);
            form.change('dailyLimit', 0);
            form.change('frequencyCap', 0);
          });
        }}
      />
      <ResettableInput
        handleClear={(frm, source) => {
          frm.change(source, 0);
        }}
      >
        <NumberInput
          source="totalLimit"
          min={0}
          step={1}
          initialValue={0}
          disabled={!formValues.limitEventType}
          validate={validateLimit}
          {...rest}
        />
      </ResettableInput>
      <ResettableInput
        handleClear={(frm, source) => {
          frm.change(source, 0);
        }}
      >
        <NumberInput
          source="dailyLimit"
          min={0}
          step={1}
          initialValue={0}
          disabled={!formValues.limitEventType}
          validate={validateLimit}
          {...rest}
        />
      </ResettableInput>
      <ResettableInput
        handleClear={(frm, source) => {
          frm.change(source, 0);
        }}
      >
        <NumberInput
          source="frequencyCap"
          min={0}
          step={1}
          initialValue={0}
          disabled={!formValues.limitEventType}
          validate={validateLimit}
          {...rest}
        />
      </ResettableInput>
    </div>
  );
};

export default LimitsSelect;
