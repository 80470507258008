import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  fieldWrap: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  icon: {
    fontSize: '0.75em',
  },
  iconGreen: {
    color: 'green',
  },
  iconRed: {
    color: 'red',
  },
}));

const BannerActiveNameField = props => {
  const { record, showId = false } = props;
  const classes = useStyles(props);
  const colorClass = record.isActive ? classes.iconGreen : classes.iconRed;

  return (
    <div className={classes.fieldWrap}>
      <Brightness1Icon className={`${classes.icon} ${colorClass}`} />
      &nbsp;
      {showId ? `${record.name} #${record.id}` : record.name}
    </div>
  );
};

BannerActiveNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
};

export default BannerActiveNameField;
