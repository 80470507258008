import React  from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { redirect } from '../../services/utils';
import FormActions from '../common/FormActions';
import {httpInput, maxTextLength} from '../../services/validtors';
import ContactsSubForm from './ContactsSubForm';

const AdvertiserEdit = props => {
  return (
    <Create {...props} actions={<FormActions />}>
      <SimpleForm redirect={redirect.toShow}>
        <TextInput source="name" validate={[required(), maxTextLength()]} />
        <TextInput source="dashboard" validate={[httpInput(), maxTextLength(250)]} />
        <ContactsSubForm />
      </SimpleForm>
    </Create>
  );
};

export default AdvertiserEdit;
