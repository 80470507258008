import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { EVENT_TYPE_TITLE } from '../config';

const useStyles = makeStyles(theme => ({
  limitRow: {
    whiteSpace: 'nowrap',
  },
}));

const LimitsSummary = ({ record, ...props }) => {
  const classes = useStyles(props);

  if (!record.limitEventType) {
    return '-';
  }
  return (
    <>
      <div>{EVENT_TYPE_TITLE[record.limitEventType]}</div>
      {record.totalLimit > 0 && (
        <div className={classes.limitRow}>Total:&nbsp;{record.totalLimit}</div>
      )}
      {record.dailyLimit > 0 && (
        <div className={classes.limitRow}>Daily:&nbsp;{record.dailyLimit}</div>
      )}
      <div className={classes.limitRow}>Delivery option:&nbsp;{record.deliveryOption}</div>
    </>
  );
};

export default LimitsSummary;
