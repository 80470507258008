import React from 'react';
import {
  DateField,
  DeleteButton,
  ImageField,
  ListButton,
  ShowController,
  ShowView,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from 'react-admin';
import FileWithCopyField from './FileWithCopyField';

const ShowFileActions = props => (
  <TopToolbar>
    <ListButton {...props} />
    <DeleteButton {...props} undoable={false} />
  </TopToolbar>
);

const ShowFile = props => {
  return (
    <ShowController {...props}>
      {cProps => (
        <ShowView
          {...props}
          {...cProps}
          actions={<ShowFileActions {...cProps} />}
        >
          <SimpleShowLayout>
            <TextField source="title" />
            <DateField source="createdAt" label="Created" showTime />
            <FileWithCopyField source="src" title="path" label="Url" download />
            {cProps.record && /image\/./.test(cProps.record.type) && (
              <ImageField source="src" addLabel={false} />
            )}
          </SimpleShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

export default ShowFile;
