export const paginationQuery = ({ pagination }) => {
  const { page, perPage } = pagination || {};
  if (!(perPage && perPage)) return {};

  return {
    page: {
      number: page,
      size: perPage,
    }
  };
};

export const sortQuery = ({ sort }) => {
  const { field, order = 'ASC' } = sort || {};
  if (!field) return {};

  const prefix = order === 'ASC' ? '' : '-';
  return {
    sort: `${prefix}${field}`
  }
};

export const filterQuery = ({ filter }) => {
  if (!filter) return {};

  return {
    filter,
  };
};

export const initListQuery = params => ({
  ...paginationQuery(params),
  ...sortQuery(params)
});


