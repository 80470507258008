export const redirect = {
  toShow: (resource, id, record) => `${resource}/${id}/show`,
  toEdit: (resource, id, record) => `${resource}/${id}/edit`,
};

export const applyFiltersIds = (ids = [], collection = {}, filter = {}) => {
  return ids.filter(id => {
    const entity = collection[id];

    if (!entity) {
      return false;
    }
    return Object.keys(filter).every(filterKey => {
      return (
        !Array.isArray(filter[filterKey]) ||
        filter[filterKey].length === 0 ||
        filter[filterKey].includes(entity[filterKey])
      );
    });
  });
};

export const applyFiltersEntities = (collection = {}, filter = {}) => {
  return applyFiltersIds(Object.keys(collection), collection, filter).reduce(
    (list, id) => ({
      ...list,
      [id]: collection[id],
    }),
    {}
  );
};
