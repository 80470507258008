import React from 'react';
import { Filter, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const BannerZoneListFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Name" source="name" alwaysOn />
      <ReferenceInput label="Site" source="siteId" reference="site" alwaysOn>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

export default BannerZoneListFilter;
