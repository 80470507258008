import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  circleStatus: {
    borderRadius: '50%',
    display: 'inline-block',
    width: '1em',
    height: '1em',
    fontSize: '.6em'
  },
});

const CircleStatus = ({ style = {}, source, record = {} }) => {
  const classes = useStyles();
  const isActive = !!record[source];
  return (
    <span
      className={classes.circleStatus}
      style={{...style, backgroundColor: isActive ? 'green' : 'blue' }}
    />
  )
};

export default CircleStatus;
