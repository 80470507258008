import React from 'react';
import { ListButton, TopToolbar } from 'react-admin';

const FormActions = ({ basePath, data = {} }) => {
  let calcBasePath = basePath;
  const { parentType, parentId } = data;
  if (parentType && parentId) {
    calcBasePath = `/${parentType}/${parentId}/show`;
  }
  return (
    <TopToolbar>
      <ListButton basePath={calcBasePath} label="back to Advertiser"/>
    </TopToolbar>
  )
};

export default FormActions;
