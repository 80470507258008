import React  from 'react';
import { Edit, SimpleForm, TextInput, required, maxLength, ReferenceInput, SelectInput } from 'react-admin';
import { parentConfig } from './config';
import FormActions from './FormActions';
import RaDatePicker from '../common/inputs/RaDatePicker';

const AdvertiserReferer = props => {
  const { record: { parentType } = {} } = props;
  const { titleField = 'name', label = 'parent' } = parentConfig[parentType];
  return (
    <ReferenceInput
      source="parentId"
      reference={parentType}
      allowEmpty
      validate={required()}
      disabled
      label={label}
    >
      <SelectInput optionText={titleField} />
    </ReferenceInput>
  )
};

const redirect = ( resource, id, record ) => {
  const { parentType , parentId } = record;
  return `/${parentType}/${parentId}/show`;
};


const NotesEdit = props => {
  return (
    <Edit {...props} undoable={false} actions={<FormActions />}>
      <SimpleForm
        redirect={redirect}
      >
        <AdvertiserReferer />

        <RaDatePicker  disabled source="updatedAt" label="Last update" />

        <TextInput multiline source="text" validate={[required(), maxLength(254, 'It must be short note (254 symbols only)')]} />
      </SimpleForm>
    </Edit>
  );
};

export default NotesEdit;
