import React  from 'react';
import { Show, ListBase, Datagrid, TextField, Pagination, DateField, Button } from 'react-admin';
import { Link } from 'react-router-dom';
import { Typography, Card, CardContent } from '@material-ui/core';
import TrafficIcon from '@material-ui/icons/Traffic';
import CardInfo from './CardInfo';
import Contacts from './Contacts';
import NotesList from '../../Notes/List';
import AddNotesBtn from '../../Notes/AddButton';
import {rowsPerPageOptions} from '../../../config';

const ShowTitle = ({ record }) => {
  return <span>Advertiser: {record ? `"${record.name}"` : ''}</span>;
};

const AddCampaignBtn = ({ advertiserId, style }) => (
  <Button
    component={Link}
    to={{
      pathname: '/campaigns/create',
      search: `?filter%5BadvertiserId%5D=${advertiserId}`,
    }}
    label="Create campaign"
    style={style}
  >
    <TrafficIcon />
  </Button>
);

const Info = props => {
  const { record } = props;
  return (
    <div>
      <div className="flex">
        <CardInfo className="flex_spacer" style={{ marginRight: '20px' }} {...props} />
        <Contacts className="flex_spacer" {...props} />
      </div>
      <br/>
      <Card>
        <CardContent>
          <Typography variant="h6">Campaigns</Typography>
          <ListBase
            title=""
            resource="campaigns"
            basePath="/campaigns"
            bulkActionButtons={false}
            actions={false}
            hasShow={true}
            hasList={true}
            hasEdit={true}
            hasCreate={true}
            filter={{ advertiserId: record.id }}
          >
            <Datagrid rowClick="show">
              <TextField source="id" />
              <TextField source="name" />
              <DateField source="campaignStart" showTime />

            </Datagrid>
            <Pagination rowsPerPageOptions={rowsPerPageOptions} />
          </ListBase>
        </CardContent>
      </Card>
      <AddCampaignBtn advertiserId={record.id} style={{ marginTop: '20px' }} />

      <div>
        <br/>
        <br/>
        <Typography component="h6" variant="h6"><span>Notes:</span></Typography>
        <br/>
        <NotesList parentId={record.id} parentType={'advertisers'} />
        <AddNotesBtn parentId={record.id} parentType={'advertisers'} style={{ marginTop: '-20px' }}/>
      </div>
    </div>
  )
};

const AdvertiserShow = props => {
  return (
    <Show title={<ShowTitle />} component="div" {...props}>
      <Info />
    </Show>
  );
};

export default AdvertiserShow;
