import React from 'react';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  fieldWrap: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: '.3em',
    marginTop: '-.4em'
  },
}));

/**
 * @param children
 * @param handleClear должен вернуть колбек, который очистит значение
 * @param clearTitle всплывашка подсказка
 * @param props
 * @returns {*}
 * @constructor
 */
const ResettableInput = ({ children, handleClear, clearTitle = 'Clear', ...props }) => {
  const form = useForm();

  const classes = useStyles(props);

  const disabled = props.disabled || children.props.disabled;
  // на случай есливнутренний компонент - тоже обертка, и в нем не задан source
  const source = props.source || children.props.source;

  const clearFunc = typeof handleClear === 'function' ? () => handleClear(form, source) : () => form.change(source, null);

  return (
    <div className={classes.fieldWrap}>
      {children}

      <IconButton
        disabled={disabled}
        className={classes.button}
        onClick={clearFunc}
        title={clearTitle}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
};

export default ResettableInput;
