import React, {useEffect, useState} from 'react';
import {
  Filter,
  TextInput,
  SelectInput,
  useDataProvider
} from 'react-admin';


const CampaignListFilter = props => {
  const dataProvider = useDataProvider();
  const [advertisers, setAdvertisers,] = useState();

  useEffect(() => {
    dataProvider.getList('advertisers', {pagination: {}})
      .then(({ data }) => {
        setAdvertisers(data);
      }).catch(e => {});
  }, [dataProvider]);

  return (
    <Filter {...props}>
      <TextInput label="Name" source="name" alwaysOn />
      <SelectInput label="Advertiser" source="advertiserId" choices={advertisers} optionText="name" />
    </Filter>
  );
};

export default CampaignListFilter;
