const httpClient = (url, config = {}) => {
  config.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...(config.headers || {})
  };

  return fetch(url, config)
    .then(response => response.json())
    .then( json => {
      if (json.status < 200 || json.status >= 300) {
        return Promise.reject(new Error(json.msg || 'Unexpected server error'));
      }

      return Promise.resolve(json);
    });
};

export default httpClient;
