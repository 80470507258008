export const createFile = async (fileData, dataProvider) => {
  // Populate entity fields with file info
  const { rawFile } = fileData;
  const requestData = {
    title: fileData.title || rawFile.name,
    type: rawFile.type,
  };

  // Get file download url
  const uploadInfo = await dataProvider
    .request(
      `/file/get-upload-info?fileName=${encodeURIComponent(rawFile.name)}`
    )
    .then(({ data }) => {
      if (!data.filePath || !data.url) {
        throw new Error('Failed to upload file');
      }
      return data;
    });
  requestData.path = uploadInfo.filePath;

  // Upload file
  await fetch(uploadInfo.url, {
    method: 'PUT',
    body: rawFile,
  }).then(response => {
    if (response.status >= 200 && response.status < 300) {
      return;
    }
    throw new Error('Unexpected server error');
  });

  // Create entity
  const entity = await dataProvider.create('file', {
    data: requestData,
  });
  return entity.data;
};
