import React from 'react';
import {
  ReferenceField,
  TextField,
  TabbedShowLayout,
  Tab,
  DateField,
  useShowController,
  Loading,
  Title,
  TopToolbar,
  ListButton,
  EditButton,
  DeleteButton,
  Labeled,
  ListBase,
  Datagrid,
  Pagination,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Button,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Card, Divider, Box, Typography, CardContent } from '@material-ui/core';
import LocalMoviesIcon from '@material-ui/icons/LocalMovies';
import useStyles from '../common/styles/show';
import TargetingShow from '../common/fields/TargetingShow';
import CustomBoolField from '../common/fields/CustomBoolField';
import BannerActiveNameField from '../Banner/fields/ActiveName';
import BannerTargetingSummaryField from '../common/fields/TargetingSummary';
import {rowsPerPageOptions} from '../../config';

const AddBannerBtn = ({ campaignId, advertiserId, style }) => (
  <Button
    component={Link}
    to={{
      pathname: '/banner/create',
      state: { record: { campaignId, advertiserId }}
    }}
    label="Create banner"
    style={style}
  >
    <LocalMoviesIcon />
  </Button>
);


const CampaignShow = props => {
  const {
    basePath,
    defaultTitle,
    loaded,
    record,
    resource,
    version,
  } = useShowController(props);
  const classes = useStyles();

  if (!loaded) {
    return <Loading />;
  }
  const showProps = { basePath, record, resource, version };

  return <>
    <Title title={defaultTitle} />
    <TopToolbar >
      <ListButton {...showProps} />
      <EditButton {...showProps} undoable={false} />
      <DeleteButton {...showProps} undoable={false} />
    </TopToolbar>
    <Card className={classes.card}>
      <TabbedShowLayout {...showProps}>
        <Tab label="Info">
          <>
            <Box className={classes.infoBoxContainer}>
              <Box className={classes.infoBoxColumn}>
                <Labeled label="Name">
                  <TextField source="name" {...showProps}/>
                </Labeled>
                <Divider />
                <Labeled label="Advertiser">
                  <ReferenceField
                    {...showProps}
                    label="Advertiser"
                    source="advertiserId"
                    reference="advertisers"
                    basePath="/advertisers"
                  >
                    <TextField source="name"/>
                  </ReferenceField>
                </Labeled>
                <Divider />
                <CustomBoolField status={record.isActive} label="Status" />
              </Box>
              <Box className={classes.infoBoxColumn}>
                <Labeled label="Date start">
                  <DateField source="dateStart" emptyText="Not set" showTime {...showProps} />
                </Labeled>
                <Divider />
                <Labeled label="Date end">
                  <DateField source="dateEnd" emptyText="Not set" showTime {...showProps} />
                </Labeled>
              </Box>
            </Box>
          </>
        </Tab>
        <Tab label="Targeting">
          <TargetingShow {...showProps} />
        </Tab>
      </TabbedShowLayout>
    </Card>
    <br/>
    <Card>
      <CardContent>
        <Typography variant="h6">Banners</Typography>
        <ListBase
          title=""
          resource="banner"
          basePath="/banner"
          bulkActionButtons={false}
          actions={false}
          hasShow={true}
          hasList={true}
          hasEdit={true}
          hasCreate={true}
          filter={{ campaign: { id: record.id } }}
        >
          <Datagrid rowClick="show">
            <TextField source="id" />
            <BannerActiveNameField label="Name" />
            <TextField source="contentType" />

            <ReferenceArrayField
              label="Banner zone"
              reference="banner_zone"
              source="bannerZonesIds"
            >
              <SingleFieldList>
                <ChipField source="name" />
              </SingleFieldList>
            </ReferenceArrayField>

            <BannerTargetingSummaryField label="Targeting" />
          </Datagrid>
          <Pagination rowsPerPageOptions={rowsPerPageOptions} />
        </ListBase>
      </CardContent>
    </Card>
    <div>
      <AddBannerBtn campaignId={record.id} advertiserId={record.advertiserId} style={{ marginTop: '20px' }} />
    </div>
  </>
};

export default CampaignShow;
