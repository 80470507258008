import React from 'react';
import { Resource } from 'react-admin';
import NotesCreate from './Create';
import NotesEdit from './Edit';

const NotesResource = permissionCheck => {
  if (!permissionCheck({ notes: ['show'] })) {
    return null;
  }
  return (
    <Resource
      name="notes"
      intent="registration"
      list={null}
      create={permissionCheck({ notes: ['create'] }) ? NotesCreate : null}
      edit={permissionCheck({ notes: ['edit'] }) ? NotesEdit : null}
    />
  );
};

export default NotesResource;
