import React, { useState } from 'react';
import { useNotify, useDataProvider, useRefresh } from 'react-admin';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';

const ResetCountersButton = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleOkClick = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    dataProvider
      .request('/banner/reset-counters', {
        method: 'POST',
        body: JSON.stringify({ bannerId: props.bannerId }),
      })
      .then(() => {
        notify('Counters has been reset');
        refresh();
      })
      .catch(e => {
        notify(`Failed to reset banner counters: ${e.message}`, 'warning');
      })
      .finally(() => {
        setIsLoading(false);
        setShowDialog(false);
      });
  };

  const handleOnClose = () => {
    if (isLoading) {
      return;
    }
    setShowDialog(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowDialog(true)}
        startIcon={<RestoreIcon />}
      >
        Reset counters
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleOnClose}>
        <DialogTitle>Reset banner counters</DialogTitle>
        <DialogContent>
          {`Are you sure you want to reset all counters for banner #${props.bannerId}`}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleOnClose}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleOkClick}
            startIcon={<CheckIcon />}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetCountersButton;
