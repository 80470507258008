import React  from 'react';
import { parse, parseUrl, stringify } from 'query-string';
import {
  Create,
  TextInput,
  required,
  BooleanInput,
  SelectInput,
  FormTab,
  TabbedForm,
  ReferenceInput
} from 'react-admin';

import FormActions from '../common/FormActions';
import { makeStyles } from '@material-ui/core/styles';
import TargetingSelect from '../common/inputs/Targeting';
import { maxTextLength } from '../../services/validtors';

const replaceUrlOnChangeAdvertiser = ({ target: { value }}) => {
  const { url, query } = parseUrl(window.location.href);
  query['filter[advertiserId]'] = value;
  const newHref = url + '?' + stringify(query);

  window.history.replaceState({}, document.title, newHref);
};

const CampaignCreate = props => {
  const { 'filter[advertiserId]': advertiserIdStr } = parse(props.location.search);
  const advertiserId = Number(advertiserIdStr) || null;

  const useStyles = makeStyles({
    card: {
      marginBottom: '1em',
      paddingLeft: '1em',
    },
  });

  const classes = useStyles(props);

  return (
    <Create {...props} actions={<FormActions />}>
      <TabbedForm
        redirect="show"
        initialValues={{
          advertiserId,
        }}
      >
        <FormTab label="Info" fullWidth>
          <TextInput source="name" validate={[required(), maxTextLength()]} />

          <ReferenceInput
            source="advertiserId"
            reference="advertisers"
            validate={required()}
            label="Advertisers"
            onChange={replaceUrlOnChangeAdvertiser}
          >
            <SelectInput
              optionText="name"
              validate={required()}
            />
          </ReferenceInput>

          <BooleanInput source="isActive" />
        </FormTab>
        <FormTab label="Targeting" className={classes.card} fullWidth>
          <FormTab label="Targeting">
            <TargetingSelect />
          </FormTab>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CampaignCreate;
