import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse, FormGroup, Typography, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import FormSelect from './FormSelect';
import { applyFiltersIds } from '../../services/utils';
import { COUNTRIES } from '../../config';

const COUNTRIES_LIST = COUNTRIES.reduce(
  (list, code) => ({
    ...list,
    [code]: {
      id: code,
      name: code,
    },
  }),
  {}
);

const useStyles = makeStyles(theme => ({
  dateInput: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'inline-block',
    maxWidth: 120,
  },
  formGroup: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const DATE_VALUE_FORMAT = 'dd.MM.yyyy';

const createDateChangeHandler = (filter, setFilter, errors, setErrors) => (
  field,
  value,
  autoFilledField
) => {
  if (!['dateFrom', 'dateTo'].includes(field)) {
    console.error('Invalid field name');
    return;
  }

  if (Number.isNaN(value)) {
    console.error('Non valid date');
    return;
  }

  const newFilter = {
    ...filter,
    [field]: value,
  };

  if (autoFilledField && newFilter.dateTo < newFilter.dateFrom) {
    newFilter[autoFilledField] = value;
  }
  const { dateFrom, dateTo } = newFilter;

  if (dateTo < dateFrom) {
    setErrors({
      [field]: 'Date to should be less than date from',
    });
  } else {
    const { dateFrom, dateTo, ...restErrors } = errors;
    setErrors(restErrors);
  }
  setFilter(newFilter);
};

const FormFilter = ({
  filter,
  setFilter,
  errors,
  setErrors,
  resourceStore,
  ...props
}) => {
  const classes = useStyles(props);
  const [isExpanded, setIsExpanded] = useState(false);

  if (!filter || !setFilter || !errors || !setErrors || !resourceStore) {
    return null;
  }
  const { dateFrom, dateTo } = filter;
  const handleChangeDate = createDateChangeHandler(
    filter,
    setFilter,
    errors,
    setErrors
  );

  return (
    <>
      <div>
        <DatePicker
          id="dateFrom"
          label="Date From"
          format={DATE_VALUE_FORMAT}
          value={dateFrom}
          error={!!errors.dateFrom}
          helperText={errors.dateFrom || ''}
          InputLabelProps={{ shrink: true }}
          className={classes.dateInput}
          onChange={val => handleChangeDate('dateFrom', val, 'dateTo')}
        />
        <DatePicker
          id="dateTo"
          label="Date To"
          format={DATE_VALUE_FORMAT}
          value={dateTo}
          error={!!errors.dateTo}
          helperText={errors.dateTo || ''}
          InputLabelProps={{ shrink: true }}
          className={classes.dateInput}
          onChange={val => handleChangeDate('dateTo', val, 'dateFrom')}
        />
      </div>
      <Typography noWrap component="div" variant="h6">
        Advanced Search&nbsp;
        <IconButton
          className={clsx(classes.expand, { [classes.expandOpen]: isExpanded })}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Typography>
      <Collapse in={isExpanded} timeout="auto">
        <FormGroup className={classes.formGroup}>
          <FormSelect
            entity="advertisers"
            title="Advertiser"
            list={resourceStore.advertisers || {}}
            selected={filter.advertiserId || []}
            setSelected={(val = []) => {
              const { bannerId = [], campaignId = [], ...rest } = filter;

              const newCamp = applyFiltersIds(
                campaignId,
                resourceStore.campaigns,
                {
                  advertiserId: val,
                }
              );
              const newBan = applyFiltersIds(bannerId, resourceStore.banner, {
                advertiserId: val,
                campaignId: newCamp,
              });

              setFilter({
                ...rest,
                advertiserId: val,
                campaignId: newCamp,
                bannerId: newBan,
              });
            }}
          />
          <FormSelect
            entity="campaigns"
            title="Campaign"
            list={resourceStore.campaigns || {}}
            filter={{ advertiserId: filter.advertiserId }}
            selected={filter.campaignId || []}
            setSelected={(val = []) => {
              const { bannerId = [], ...rest } = filter;
              const newBan = applyFiltersIds(bannerId, resourceStore.banner, {
                campaignId: val,
              });
              setFilter({
                ...rest,
                campaignId: val,
                bannerId: newBan,
              });
            }}
          />
          <FormSelect
            entity="banner"
            title="Banner"
            list={resourceStore.banner || {}}
            filter={{
              campaignId: filter.campaignId,
              advertiserId: filter.advertiserId,
            }}
            selected={filter.bannerId || []}
            setSelected={(val = []) => {
              setFilter({
                ...filter,
                bannerId: val,
              });
            }}
          />
          <FormSelect
            entity="country"
            title="Country"
            list={COUNTRIES_LIST}
            selected={filter.country || []}
            setSelected={(val = []) => {
              setFilter({
                ...filter,
                country: val,
              });
            }}
          />
          <FormSelect
            entity="site"
            title="Site"
            list={resourceStore.site || {}}
            selected={filter.siteId || []}
            setSelected={(val = []) => {
              const { zoneId = [], ...rest } = filter;
              const newZone = applyFiltersIds(
                zoneId,
                resourceStore.banner_zone,
                {
                  siteId: val,
                }
              );
              setFilter({
                ...rest,
                siteId: val,
                zoneId: newZone,
              });
            }}
          />
          <FormSelect
            entity="banner_zone"
            title="Banner Zone"
            list={resourceStore.banner_zone || {}}
            filter={{ siteId: filter.siteId }}
            selected={filter.zoneId || []}
            setSelected={(val = []) => {
              setFilter({
                ...filter,
                zoneId: val,
              });
            }}
          />
          <FormSelect
            entity="device_type"
            title="Device Type"
            list={resourceStore.device_type}
            selected={filter.deviceType || []}
            setSelected={(val = []) => {
              setFilter({
                ...filter,
                deviceType: val,
              });
            }}
          />
          <FormSelect
            entity="os"
            title="OS"
            list={resourceStore.os}
            selected={filter.os || []}
            setSelected={(val = []) => {
              setFilter({
                ...filter,
                os: val,
              });
            }}
          />
          <FormSelect
            entity="browser"
            title="Browser"
            list={resourceStore.browser}
            selected={filter.browser || []}
            setSelected={(val = []) => {
              setFilter({
                ...filter,
                browser: val,
              });
            }}
          />
        </FormGroup>
      </Collapse>
    </>
  );
};

export default FormFilter;
