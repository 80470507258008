import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  DeleteButton,
  ShowButton,
  UrlField,
} from 'react-admin';
import ZonesListActions from './ListActions';
import ZonesListFilter from './ListFilter';
import CustomPagination from '../common/inputs/CustomPagination';

const RowActions = ({ label, headerClassName, ...props }) => (
  <div className="text-right">
    <ShowButton {...props} />
    <EditButton {...props} />
    <DeleteButton {...props} undoable={false} />
  </div>
);

const EmailsList = ({ record = {} }) => {
  const { contacts = [] } = record;

  return contacts.map((item, index) => (
    <span key={index}>
      {index > 0 ? ', ' : ''}
      <EmailField source="email" record={item} />
    </span>
  ));
};

const AdvertisersList = props => {
  return (
    <List
      {...props}
      pagination={<CustomPagination />}
      actions={<ZonesListActions />}
      filters={<ZonesListFilter />}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" fullWidth />
        <EmailsList label="Emails"/>
        <UrlField source="dashboard" target="_blank" emptyText="-" />
        <RowActions label="Actions" headerClassName="text-right" />
      </Datagrid>
    </List>
  );
};

export default AdvertisersList;
