import React from 'react';
import {
  TopToolbar,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  Datagrid,
  useShowController,
  Loading,
  Title,
  EditButton,
  DeleteButton,
  ListBase,
  Pagination,
  ListButton, useRefresh,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import BannerActiveNameField from '../Banner/fields/ActiveName';
import CommonTargetingSummaryField from '../common/fields/TargetingSummary';
import BannerTargetingSourceField from '../Banner/fields/TargetingSource';
import {rowsPerPageOptions} from '../../config';
import RemoveFromZoneButton from './RemoveFromZoneButton';

const useStyles = makeStyles({
  card: {
    marginBottom: '1em',
  },
  actionPanel: {
    float: 'right',
  },
});

const BannerZoneShow = props => {
  const {
    basePath,
    loaded,
    record,
    resource,
    version,
  } = useShowController(props);
  const zoneId = record?.id
  const refresh = useRefresh()

  const classes = useStyles(props);

  if (!loaded) {
    return <Loading />;
  }
  const showProps = { basePath, record, resource, version };

  // TODO banners list
  return (
    <>
      <Title title={`Banner Zone: ${record ? `"${record.name}" (#${record.id})` : ''}`} />
      <TopToolbar>
        <ListButton {...showProps} />
        <EditButton {...showProps} undoable={false} />
        <DeleteButton {...showProps} undoable={false} />
      </TopToolbar>
      <Card className={classes.card}>
        <SimpleShowLayout {...showProps}>
          <TextField source="id" />
          <TextField source="name" />
          <ReferenceField label="Site" source="siteId" reference="site">
            <TextField source="name" />
          </ReferenceField>
        </SimpleShowLayout>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6">Banners</Typography>
          <ListBase
            title=""
            resource="banner"
            basePath="/banner"
            bulkActionButtons={false}
            actions={false}
            hasShow={true}
            hasList={true}
            hasEdit={true}
            hasCreate={true}
            filter={{ bannerZone: { id: record.id } }}
          >
            <Datagrid rowClick="show">
              <TextField source="id" />
              <RemoveFromZoneButton label="Remove banner" refresh={refresh} path={basePath} zoneId={zoneId} />
              <BannerActiveNameField label="Name" />
              <TextField source="contentType" />
              <ReferenceField
                label="Advertiser"
                source="advertiserId"
                reference="advertisers"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Campaign"
                source="campaignId"
                reference="campaigns"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField label="Priority" source="priority" />
              <CommonTargetingSummaryField label="Targeting" />
              <BannerTargetingSourceField label="Targeting source" />
            </Datagrid>
            <Pagination rowsPerPageOptions={rowsPerPageOptions} />
          </ListBase>
        </CardContent>
      </Card>
    </>
  );
};

export default BannerZoneShow;
