import React, { useEffect } from 'react';
import { Title, getResources, useRedirect } from 'react-admin';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export default () => {
  const redirect = useRedirect();
  const resources = useSelector(getResources);
  // Иммитация дефолтного поведения
  useEffect(() => {
    if (resources.length === 0) {
      return;
    }
    const firstRes = resources.find(el => el.hasList);

    if (!firstRes) {
      return;
    }
    redirect(`/${firstRes.name}`);
  }, [resources.length]);
  return (
    <Card>
      <Title title="Dashboard" />
      <CardContent>Welcome to admin panel</CardContent>
    </Card>
  );
};
