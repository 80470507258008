import { API_URL } from '../../config';
import JWTManager from '../../services/JWTManager';
import customDataProviderBuilder from './providerBuilder';
import httpRequester from './httpClient';

const httpClient = (url, options = {}) => {
  options.headers = options.headers || {};

  return JWTManager.getInitToken().then(token => {
    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }
    return httpRequester(url, options);
  })
};

const dataProvider = customDataProviderBuilder(API_URL, { httpClient });

export default dataProvider;
