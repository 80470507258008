import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import ZonesListActions from './ListActions';
import ZonesListFilter from './ListFilter';
import CustomPagination from '../common/inputs/CustomPagination';

const RowActions = props => (
  <div style={{ textAlign: 'right' }}>
    <EditButton {...props} undoable={false} />
    <DeleteButton {...props} undoable={false} />
  </div>
);

const BannerZoneList = props => {
  return (
    <List
      {...props}
      actions={<ZonesListActions />}
      filters={<ZonesListFilter />}
      bulkActionButtons={false}
      sort={{ field: 'siteName', order: 'DESC' }}
      pagination={<CustomPagination />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" fullWidth />
        <ReferenceField label="Site" source="siteId" reference="site" sortBy="siteName">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Banners"
          render={record =>
            record.bannersIds ? record.bannersIds.length : 0
          }
        />
        <RowActions />
      </Datagrid>
    </List>
  );
};

export default BannerZoneList;
