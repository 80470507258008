import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';
import inflection from 'inflection';
import { getPermissionsChecker } from './services/Permissions';

const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const { loading: loadingPermissions, permissions } = usePermissions();
  const checkPermissions = useMemo(() => loadingPermissions ? () => false : getPermissionsChecker(permissions), [loadingPermissions]);

  return (
    <div>
      {resources.filter(({ hasList }) => hasList).map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            inflection.humanize(inflection.pluralize(resource.name))
          }
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {checkPermissions({statistics: ['show']}) && (
        <>
          <MenuItemLink
            to="/statistics/general"
            primaryText="Statistics"
            leftIcon={<LabelIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </>
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
