import React from 'react';
import {
  TextField,
  ReferenceField,
  DateField,
  Datagrid,
  useShowController,
  Loading,
  Title,
  EditButton,
  DeleteButton,
  ListBase,
  Pagination,
  ListButton,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  Labeled,
  FunctionField,
} from 'react-admin';
import { Card, CardContent, Box, Typography, Button } from '@material-ui/core';
import SecurityIcon from '@material-ui/icons/Security';
import BannerActiveNameField from './fields/ActiveName';
import getContentTypeFields from './fields/getContentTypeFields';
import VisibilityIcon from '@material-ui/icons/Visibility';
import useStyles from '../common/styles/show';
import TargetingShow from '../common/fields/TargetingShow';
import { BANNER_TYPES_LABELS } from './config';
import { rowsPerPageOptions } from '../../config';
import ShowLimits from './ShowLimits';

const isUnSettable = val => [undefined, null].includes(val);

const ShowSizes = ({ record: { adData }, ...props }) => {
  const { styles } = adData || {};
  const { width, height } = styles || {};
  if ([width, height].every(isUnSettable)) return null;

  return (
    <Labeled label="Sizes" {...props}>
      <div>
        {!height && <>Width: {width}</>}
        {!width && <>Height: {height}</>}
        {width && height && (
          <>
            {width} &#10005; {height}
          </>
        )}
      </div>
    </Labeled>
  );
};

const DateFieldsSection = props => {
  return (
    <>
      <Labeled label="Date start">
        <DateField source="dateStart" emptyText="Not set" {...props} />
      </Labeled>
      <Labeled label="Date end">
        <DateField source="dateEnd" emptyText="Not set" {...props} />
      </Labeled>
    </>
  );
};

const DateFieldsInheritSection = props => {
  const {
    record: { isCustomRules },
  } = props;

  if (isCustomRules) {
    return <DateFieldsSection {...props} />;
  }
  return (
    <ReferenceField
      source="campaignId"
      reference="campaigns"
      link={false}
      {...props}
    >
      <DateFieldsSection />
    </ReferenceField>
  );
};

const BannerShow = props => {
  const { basePath, loaded, record, resource, version } = useShowController(
    props
  );
  const classes = useStyles();

  if (!loaded) {
    return <Loading />;
  }
  const showProps = { basePath, record, resource, version };
  const contentTypeFields = getContentTypeFields(record);

  const redirectToPreview = () => {
    window.localStorage.setItem('record', JSON.stringify(record));
    window.open('/preview.html', '_blank');
  };

  return (
    <>
      <Title title={`Banner: ${record ? `"${record.name}"` : ''}`} />
      <TopToolbar>
        <ListButton {...showProps} />
        <EditButton {...showProps} undoable={false} />
        <DeleteButton {...showProps} undoable={false} />
      </TopToolbar>

      <Card className={classes.card}>
        <TabbedShowLayout {...showProps}>
          <Tab label="Info">
            <>
              <Button
                variant="contained"
                color={'primary'}
                onClick={() => redirectToPreview()}
                startIcon={<VisibilityIcon />}
              >
                Show preview
              </Button>
              <Box className={classes.infoBoxContainer}>
                <Box className={classes.infoBoxColumn}>
                  <Labeled label="Name">
                    <BannerActiveNameField {...showProps} showId={true} />
                  </Labeled>
                  <Labeled label="Advertiser">
                    <ReferenceField
                      {...showProps}
                      source="advertiserId"
                      reference="advertisers"
                      basePath="/advertisers"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Labeled>
                  <Labeled label="Campaign">
                    <ReferenceField
                      {...showProps}
                      source="campaignId"
                      reference="campaigns"
                      basePath="/campaigns"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Labeled>
                </Box>
                <Box className={classes.infoBoxColumn}>
                  <DateFieldsSection {...showProps} />
                </Box>
              </Box>
            </>

            <FunctionField
              render={record => `${record.cpm}$`}
              label="CPM"
              {...showProps}
            />

            <FunctionField
              render={record => BANNER_TYPES_LABELS[record.contentType]}
              label="Content Type"
              {...showProps}
            />
            {contentTypeFields}

            <ShowSizes />

            <Labeled label="Iframe Wrapped">
              {record.adData && record.adData.isIframeWrapped ? (
                <SecurityIcon />
              ) : (
                <span>No</span>
              )}
            </Labeled>
          </Tab>
          <Tab label="Limits">
            <ShowLimits {...showProps} />
          </Tab>
          <Tab label="Targeting">
            <>
              <Typography gutterBottom={true}>
                Source: {record.isCustomRules ? 'Banner' : 'Campaign'}
              </Typography>
            </>
            <TargetingShow
              {...showProps}
              headerSlot={
                <Typography className={classes.subTitle}>
                  Priority: &nbsp;
                  <TextField source="priority" {...showProps} />
                </Typography>
              }
            />
          </Tab>
        </TabbedShowLayout>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6">Placement</Typography>
          <ListBase
            title=""
            resource="banner_zone"
            basePath="/banner_zone"
            bulkActionButtons={false}
            actions={false}
            hasShow={true}
            hasList={true}
            hasEdit={true}
            hasCreate={true}
            filter={{ banner: { id: record.id } }}
          >
            <Datagrid rowClick="show">
              <TextField source="id" />
              <ReferenceField label="Site" source="siteId" reference="site">
                <TextField source="name" />
              </ReferenceField>
              <TextField source="name" />
            </Datagrid>
            <Pagination rowsPerPageOptions={rowsPerPageOptions} />
          </ListBase>
          Return to{' '}
          <Button variant="contained" color="primary">
            <ReferenceField
              {...showProps}
              source="campaignId"
              reference="campaigns"
              link={(record, reference) =>
                `/${reference}/${record.campaignId}/show`
              }
              style={{ color: '#fff' }}
            >
              <TextField source="name" />
            </ReferenceField>
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default BannerShow;
