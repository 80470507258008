import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Collapse,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useLabelStyles = makeStyles(theme => ({
  label: {
    minWidth: '92px',
  },
}));

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const GROUP_FIELD_CONFIG = {
  advertiserId: {
    require: [],
    required: ['bannerId', 'campaignId'],
  },
  campaignId: {
    require: ['advertiserId'],
    required: ['bannerId'],
  },
  bannerId: {
    require: ['advertiserId', 'campaignId'],
    required: [],
  },
  siteId: {
    require: [],
    required: ['zoneId'],
  },
  zoneId: {
    require: ['siteId'],
    required: [],
  },
};

const FormGroupBy = ({ groupBy, setGroupBy, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const groupByLocal = useMemo(
    () =>
      (groupBy || []).reduce((con, el) => ({ ...con, [el]: true }), {
        advertiserId: false,
        campaignId: false,
        bannerId: false,
        siteId: false,
        zoneId: false,
        country: false,
        eventDate: false,
        deviceType: false,
        os: false,
        browser: false,
      }),
    [groupBy]
  );
  const labelClasses = useLabelStyles(props);
  const classes = useStyles(props);

  const handleChange = event => {
    const realState = {
      ...groupByLocal,
      [event.target.name]: event.target.checked,
    };
    const conf = GROUP_FIELD_CONFIG[event.target.name];

    if (event.target.checked) {
      if (conf && conf.require) {
        conf.require.forEach(field => {
          realState[field] = true;
        });
      }
    } else {
      if (conf && conf.required) {
        conf.required.forEach(field => {
          realState[field] = false;
        });
      }
    }
    setGroupBy(Object.keys(realState).filter(el => realState[el]));
  };

  return (
    <>
      <Typography noWrap component="div" variant="h6">
        Group By&nbsp;
        <IconButton
          className={clsx(classes.expand, { [classes.expandOpen]: isExpanded })}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Typography>

      <Collapse in={isExpanded} timeout="auto">
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name="advertiserId"
                checked={groupByLocal.advertiserId}
                onChange={handleChange}
              />
            }
            label="Advertiser"
            labelPlacement="start"
            classes={labelClasses}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="campaignId"
                checked={groupByLocal.campaignId}
                onChange={handleChange}
              />
            }
            label="Campaign"
            labelPlacement="start"
            classes={labelClasses}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="bannerId"
                checked={groupByLocal.bannerId}
                onChange={handleChange}
              />
            }
            label="Banner"
            labelPlacement="start"
            classes={labelClasses}
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name="country"
                checked={groupByLocal.country}
                onChange={handleChange}
              />
            }
            label="Country"
            labelPlacement="start"
            classes={labelClasses}
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name="siteId"
                checked={groupByLocal.siteId}
                onChange={handleChange}
              />
            }
            label="Site"
            labelPlacement="start"
            classes={labelClasses}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="zoneId"
                checked={groupByLocal.zoneId}
                onChange={handleChange}
              />
            }
            label="Zone"
            labelPlacement="start"
            classes={labelClasses}
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name="deviceType"
                checked={groupByLocal.deviceType}
                onChange={handleChange}
              />
            }
            label="Device Type"
            labelPlacement="start"
            classes={labelClasses}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="os"
                checked={groupByLocal.os}
                onChange={handleChange}
              />
            }
            label="OS"
            labelPlacement="start"
            classes={labelClasses}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="browser"
                checked={groupByLocal.browser}
                onChange={handleChange}
              />
            }
            label="Browser"
            labelPlacement="start"
            classes={labelClasses}
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                name="eventDate"
                checked={groupByLocal.eventDate}
                onChange={handleChange}
              />
            }
            label="Event Date"
            labelPlacement="start"
            classes={labelClasses}
          />
        </FormGroup>
      </Collapse>
    </>
  );
};

export default FormGroupBy;
